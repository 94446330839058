/**
 * Extensão do Modernizr
 *
 * @author  Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since   2015
 */
export class ModernizrExt {
    /**
     * Construtor
     */
    constructor(navigation) {
        this.navigation = navigation;
        this.mobile_detect = new MobileDetect(navigator.userAgent);
        Modernizr.addTest({
            no_menu: $.proxy( this.no_menu(), this ),
            partial_menu: $.proxy( this.partial_menu(), this )
        });

        this.check_svg().border_ie();
    }

    /**
     * Desliga todos os efeitos
     *
     * @return Boolean Determina se o ambiente não suporta nenhum efeito
     */
    no_menu(){
        // Caso não consiga calcular o menu
        if ( ! Modernizr.csscalc ) return true;

        // Caso esteja utilizando um webkit bastante antigo
        if ( this.mobile_detect.version('Webkit') < 537.36 ) return true;

        return false;
    }

    /**
     * Determina se somente os efeitos mais simples podem ser executados
     */
    partial_menu(){
        // Caso esteja utilizando um webkit intermediário
        var webkit = this.mobile_detect.version('Webkit');
        var android = this.mobile_detect.version('Android');
        var chrome = this.mobile_detect.version('Chrome');

        if( webkit == 537.36 && android < 5 && chrome <= 30 ) return true;

        if(this.mobile_detect.version('Build') && chrome <= 36 ) return true;

        if(this.mobile_detect.is('IE')) return true;

        return false;
    }

    /**
     * Verifica se é IE, se for troca por uma logo png
     */
    check_svg(){
        if(location.pathname !== "/") return this;

        if(this.mobile_detect.is('IE')) {
            $(".logo").removeClass('logo').addClass('logo_fallback');
        }

        return this;
    }

    /**
     * Caso seja IE corrije a borda
     *
     * @return this
     */
    border_ie(){
        if(this.mobile_detect.version('IE') < 11) {
            $('#extranav>ul>li, #gohome, #goback').css('border-style', 'none');
            $('#extranav li.help.page_only').hide();

            if( this.navigation.is_news() ){
                $('#goback').css('float', 'none')
                        .css('margin-left', '40px')
                        .css('width', '40px');
            }
        }

        return this;
    }
}