 /**
 * Conversões
 *
 * @author  Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since   2015
 */
export class Conversion {
	/**
	 * Construtor
	 */
	constructor(selector="#view-view") {
		var obj = this ;
		this.conversions = {
			"artHtml5": this.artHtml5 ,
			"graphs": this.graphs ,
			"video": this.video ,
			"gallery": this.gallery
		};
		this.execute( selector , obj ) ;
		this.add_date_erramos() ;
		this.graphs_live() ;
	}

	/**
	 * Executa as conversões
	 */
	execute( selector , self ) {
		var elements = $( selector ) ;

		if ( elements.length ) {
			elements.each( function( i ) {
				var html = $( this ).html() ;
				$.each( self.conversions , ( name , action ) => {
					html = action( html , self , $( this ) ) ;
				} ) ;

				$( this ).html( html ) ;
			} ) ;
		}
	}

	/**
	 * Recupera o parametro com base na URL
	 */
	get_parameter_from_url( url , parameter ) {
		var url_pattern = new RegExp(
			"^.+"+parameter+"=(\\d+)(.?)+$"
		) ;
		var match = url_pattern.exec( url ) ;
		if ( match ) {
			return match[1] ;
		}
	}

	/**
	 * Com base em um padrão realiza as trocas localizadas
	 */
	get_output( pattern , data ) {
		var var_pattern = new RegExp( "\{([^\}]+)\}" , "gim" ) ;
		var output = pattern ;

		var variable = var_pattern.exec( pattern ) ;
		while ( variable ) {
			variable = variable ? variable : [];

			if ( variable[1] in data ) {
				output = output.replace( "{" + variable[1] + "}" , data[variable[1]] ) ;
			}

			variable = var_pattern.exec( pattern ) ;
		}

		return output ;
	}

	/**
	 * Com base na URL define o formamto do vídeo
	 */
	parse_format_video( item ) {
		if ( item.url ) {
			if ( item.url.indexOf( ".3gp" ) > -1  ) {
				return "video/3gp" ;
			}

			return "video/mp4" ;
		}
	}

	/**
	 * Realiza as trocas das fontes do vídeo
	 */
	build_sources_video( sources ) {
		var html = "" ;
		var source_pattern = "<source src=\"{url}\" type=\"{format}\">\n" ;

		$.each( sources , ( i , element ) => {
			element.format = this.parse_format_video( element ) ;
			html += this.get_output( source_pattern , element ) ;
		} ) ;

		return html ;
	}

	/**
	 * Arte HTML5
	 */
	artHtml5( html , obj ) {
		var input_pattern = new RegExp(
			"<p\\s*class=\"artHtml5\">[\\n\\s]*<a(\\s*rel=\"nofollow\")*\\s*href=\"([^\"]+)\"(\\s*rel=\"nofollow\")*>(.*?)<\/a>[\\n\\s]*<\/p>" ,
			"gim"
		) ;
		var output_pattern = '' ;
		output_pattern += '<p class="html5Art">' ;
		output_pattern += '<a class="html5art" href="#operate/modal">' ;
		output_pattern += '<span data-art="{2}">Infogr&aacute;fico: {4}</span>' ;
		output_pattern += '</a>' ;
		output_pattern += '</p>' ;

		var match = input_pattern.exec( html ) ;
		while ( match ) {
			match = match ? match : [] ;
			html = html.replace( match[0] , obj.get_output( output_pattern , match ) ) ;

			match = input_pattern.exec( html ) ;
		}

		return html ;
	}

	/**
	 * Trocas do folha graficos
	 */
	graphs( html , obj ) {
		var input_pattern = new RegExp(
			"<p\\s*class=\"folhagraficos\">[\\n\\s]*<a\\s*href=\"(.*?)\"\\s*>(.*?)<\/a>[\\n\\s]*<\/p>" ,
			"gim"
		) ;
		var output_pattern = '';
		output_pattern += '<div class="folha-graphics">' ;
		output_pattern += '<div class="keep-ratio" style="padding-top: {height}px">' ;
		output_pattern += '<iframe src="{url}" width="{width}" height="{height}"></iframe>' ;
		output_pattern += '</div>' ;
		output_pattern += '</div>' ;

		var match = input_pattern.exec( html ) ;
		while ( match ) {
			match = match ? match : [];
			var graph_data = {
				"url" : match[1] ,
				"height" : obj.get_parameter_from_url( match[1] , "h" ),
				"width" : obj.get_parameter_from_url( match[1] , "w" )
			} ;
			html = html.replace( match[0] , obj.get_output( output_pattern , graph_data ) ) ;
			match = input_pattern.exec( html ) ;
		}

		return html ;
	}

	graphs_live() {
		var interval = setInterval(function() {
			if (document.readyState === 'complete') {
				var graph = document.querySelector('.folhagraficos');

				if (graph) {
					var url = graph.getElementsByTagName('a')[0].href;
		  			var vars = url.split("?");
		  			var param = vars[1].split("&");
					var container = document.createElement("div");
					var div1 = document.createElement("div");
					var div2 = document.createElement("div");
					var iframe = document.createElement("iframe");
					var height = param[1].replace('h=', '');

					iframe.src = vars[0];
					iframe.height = height;
					iframe.style.height = (height + 'px');
					div1.className = "folha-graphics";
					div1.style.height = (height + 'px');
					div2.className = "keep-ratio";
					div2.appendChild(iframe)
					div1.appendChild(div2)
					container.appendChild(div1);

					graph.innerHTML = container.innerHTML;
					clearInterval(interval);
				}
			}
		}, 500)
	}

	/**
	 * Vídeo
	 */
	video( html , obj , element ) {
		var input_pattern = new RegExp( "<p\\s*class=\"wideVideoPlayer\"\\s*>[\\s\\n]*<a.*?href=\"(.*?)\".*?>[\\s\\n]*.*?[\\s\\n]*<\/a>[\\s\\n]*<\/p>" , "gim" ) ;
		var match = input_pattern.exec( html ) ;
		while ( match ) {
			match = match ? match : [];

			var url = match[1] ;
			var url_parts = url.split( "/" ) ;
			url_parts.pop() ;
			var media_id;

			// UOL Mais
			if ( url.indexOf( "uolmais" ) > -1 || url.indexOf( "mais.uol" ) > -1 ) {
				var media_id_pattern = new RegExp( "\/mediaId=([0-9]+)&" , "i" ) ;
				media_id = media_id_pattern.exec( url ) ;

				if ( media_id ) {
					media_id = media_id[1] ;
				}
				else {
					media_id = url_parts.pop() ;
					media_id_pattern = new RegExp( "\/([0-9]+).*" , "gim" ) ;
					media_id = media_id_pattern.exec( url ) ;
					if ( media_id ) {
						media_id = media_id[1] ;
					}
				}

				var uolmais_pattern = '' ;
				uolmais_pattern += '<div class="video-article">' ;
				uolmais_pattern += '<iframe class="video-uolmais" src="//player.mais.uol.com.br/?mediaId=' + media_id + '" frameborder="0" allowfullscreen>' ;
				uolmais_pattern += '</iframe>' ;
				uolmais_pattern += '</div>' ;

				html = html.replace( match[0] , uolmais_pattern ) ;
			}

			// Vimeo
			if ( url.indexOf( "vimeo.com" ) > -1 ) {
				media_id = url_parts.pop() ;

				$.ajax( {
					"url" : "https://vimeo.com/api/oembed.json?url=https://vimeo.com/" + media_id ,
					"dataType": "jsonp" ,
					"context": {
						"match" : match ,
						"element" : element ,
						"obj" : obj
					} ,
					"success" : obj.video_vimeo_success
				} ) ;
			}

			// YouTube
			var youtube_pattern = new RegExp(
				"https?:\/\/(www\.)?(youtu((be\.com)|(\.be)))\/(watch\\?v=)?([a-zA-Z0-9-_]+)" ,
				"gim"
			) ;
			if ( url.search( youtube_pattern ) != -1 ) {
				media_id = youtube_pattern.exec( url ) ;
				if ( media_id ) {
					media_id = media_id.pop() ;
					var output_pattern = '' ;
					output_pattern += '<div class="video-article">' ;
					output_pattern += '<div class="youtube-player video-player" data-src="{mediaId}"></div>' ;
					output_pattern += '<a href="#operate/video_replace" class="video-player video-{mediaId}">' ;
					output_pattern += '<i></i>' ;
					output_pattern += '<img src="//i1.ytimg.com/vi/{mediaId}/mqdefault.jpg" width="100%">' ;
					output_pattern += '</a>' ;
					output_pattern += '</div>' ;
					html = html.replace( match[0] , obj.get_output( output_pattern , { "mediaId" : media_id } ) ) ;
				}
			}

			match = input_pattern.exec( html ) ;
		}

		return html ;
	}

	video_vimeo_success( data ) {
		if ( data.html ) {
			var html = this.element.html() ;
			html = html.replace( this.match[0] , data.html ) ;
			this.element.html( html ) ;
		}
	}

	/**
	 * Galeria
	 */
	gallery( html , obj ) {
		var input_pattern = new RegExp(
			"<p\\s*class=\"gallery\">[\\n\\s]*<a\\s*href=\"(https:\/\/((\d+\.)?dev\.|test\.)?fotografia\.folha\." +
			"(uol\.)?com\.br\/galerias\/(nova\/)?([0-9a-zA-Z-_]+)(#foto-([0-9]+))?)\">[\\n\\s]*<img\\s*src=\"(.*?)\".*?" +
			"alt=\"(.*?)\"[^>]+><\/a>[\\n\\s]*<\/p>" ,
			"gim"
		) ;

		let uid = ('gallery' + Math.random()).replace('.', '');
		var match = input_pattern.exec( html ) ;

		var output_pattern = '';
		output_pattern += '<div class="articleGallery" id="' + uid + '_{6}">' ;
		output_pattern += '<a data-url="{1}" href="/gallery/' + location.search;
		output_pattern += '#galeria={6}{foto},ref=' ;
		output_pattern += location.pathname.phptrim('/') + '">' ;
		output_pattern += '<img src="{9}" alt="{10}">' ;
		output_pattern += '</a>' ;
		output_pattern += '</div>' ;

		while ( match ) {
			match = match ? match : [] ;

			match.foto = "" ;
			if ( match[8] ) {
				match.foto = ",foto=" + match[8] ;
			}

			html = html.replace( match[0] , obj.get_output( output_pattern , match ) ) ;
			match = input_pattern.exec( html ) ;
		}

		return html ;
	}

	/**
	 * Adiciona a data/hora de correção da noticia, no local com o texto explicativo de ERRAMOS
	 */
	add_date_erramos() {
		if ( $( ".aside-erramos p.erramos" ).size() && page_info.update_date && page_info.update_time ) {
			let text = `<time> ${page_info.update_date} <i class="icon-clock"></i> ${page_info.update_time} </time> ` ;
			$( ".aside-erramos p.erramos" ).html( "Erramos" ) ;
			$( ".aside-erramos ul li:first" ).prepend( text ) ;
		}
	}
}
