/**
 * Data
 *
 * @author  Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since   2015
 */
export class DateBr {

	/**
     * Construtor
     */
    constructor() {
        this.day = new Array(
			"Domingo",
			"Segunda",
			"Terça",
			"Quarta",
			"Quinta",
			"Sexta",
			"Sábado"
		);

		this.month = new Array(
			"Janeiro",
			"Fevereiro",
			"Março"    ,
			"Abril",
			"Maio",
			"Junho",
			"Julho",
			"Agosto",
			"Setembro",
			"Outubro",
			"Novembro",
			"Dezembro"
		);

		this.format_date_content();
		this.add_icon_clock();
    }

	/**
	 * Retorna o nome do dia da semana atual formatado
	 *
	 * @return {String} Dia da semana
	 */
	get_day_name() {
		var date = new Date();
		var day_name = date.getDay();
		return this.day[day_name];
	}

	/**
	 * Retorna o nome do mês formatado
	 *
	 * @return {String} Nome do mês formatado
	 */
	get_month_name(current_date){
		var date = current_date || new Date();
		var month_name = date.getUTCMonth();
		return this.month[month_name];
	}

	/**
	 * Retorna a data formata no seguinte formato:
	 * Segunda-feira, 1 de janeiro de 2015
	 *
	 * @param {date} current_date Data para ser utilizada
	 *
	 * @return {String} Data no formato brasileiro
	 */
	get_full_formatted(current_date){
		let date = current_date || new Date();
		let day = date.getDate() == 1 ? '1º' : date.getDate().toString();
		let day_name = this.get_day_name();
		let month = this.get_month_name(current_date);
		let fullyear = date.getFullYear().toString();

		return `${day_name}, ${day} de ${month} de ${fullyear}`;
	}

	/**
	 * Retorna a data formata no seguinte formato:
	 * Segunda-feira, 1 de janeiro de 2015
	 *
	 * @param {date} current_date Data para ser utilizada
	 *
	 * @return {String} Data no formato brasileiro
	 */
	get_full_formatted_without_day_name(current_date){
		var date = current_date || new Date();
		var day = date.getDate();

		if ( day == 1) day = "1°";

		return '{day} de {month} de {fullyear}'
			.replace('{day}', day)
			.replace('{month}', this.get_month_name(current_date))
			.replace('{fullyear}', date.getFullYear());
	}

	/**
	 * Retorna a data formata no seguinte formato:
	 * 21h05
	 *
	 * @param {date} current_date Data para ser utilizada
	 *
	 * @return {String} Data no formato brasileiro
	 */
	get_hour_formatted(current_date){
		var date = current_date || new Date();
		var current_hour = ( "0" + date.getHours() ).slice( -2 );
		var current_minute = ( "0" + date.getMinutes() ).slice( -2 );
		return current_hour + "h" + current_minute;
	}

	/**
	 * Formata a data do topo e rodapé dentro das capas
	 */
	format_date_content() {
		console.log(this.constructor.name, this.format_date_content.name);

		// Para converter corretamente a data precisa estar em ISO-8601
		let today_date = $( "#today_date time" ).text().replace(' ', 'T') + '-03:00';

		// 1° página
		$( "#today_date time" ).html(
			this.get_full_formatted(new Date(today_date))
		).show();

		$( "li.date" ).not( ".special-news-list li.date" ).each((index, item) => {
			var $item = $(item);
			var [dia, mes, ano] = $item.text().split("/");

			$item.html(
				this.get_full_formatted_without_day_name(
					new Date(`${ano}-${mes}-${dia}T00:00:00-03:00`)
				)
			).show();
		});
	}

	add_icon_clock() {
		$( ".special-news-list li time" ).prepend( '<i class="icon-clock" />' ) ;
	}
}