/**
 * Instagram
 *
 * @author	Anderson Felix - (a.felix) <anderson.felix@grupofolha.com.br>
 * @since	2015
 */
export class Instagram {
	/**
	 * Construtor
	 */
	constructor() {
		var base = this ;

		$( "p.instagram" ).each( function() {
			base.mount( $( this ) ) ;
		} ) ;
	}

	/**
	 * Replace p.instagram > a
	 * em iframe
	 */
	mount( $el ) {
		let url = $el.find( "a" ).attr( "href" ) ,
			hash = url.match( /(^https?:\/\/([\w\.]*)instagram\.com\/p\/)([^\/]+)(?:\/)?/i)[3];

		$el.html(
			$( "<iframe \/>" )
				.attr( 'src' , hash.replace( /^(.*)$/ , 'https://instagram.com/p/$1/embed/' ) )
				.css( {
					border: 0 ,
					height: 450 ,
					width: "100%"
				} )
		) ;
	}
}