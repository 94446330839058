/**
 * Newsletter
 *
 * @author	Evandro Santos - (ev.santos) <evandro.santos@grupofolha.com.br>
 * @since	2015
 * 
 */

export class Newsletter{
	/*
	 * COnstructor
	 */
	
	constructor() {
		var base = this,
			emailRegexp = {
	            incomplete: /^\w*[\w\-\.]*\@?\w*[\w\-\.]*\.?\w*$/i ,
	            full: /^\w[\w\-\.\+]+\@\w[\w\-\.]+\.\w+$/i
        	} ;

		base.$el = $('.newsletter');

		base.options = {

	        ajax: {
        		url: '//perfil.folha.com.br/newsletter/opt-in.jsonp',
       			dataType: 'jsonp'
			} ,
		    selectors: {
		        form: {
		            home: '#form_newsletter' ,
		            modal: '#form_newsletter_modal'
		        } ,
		        error: '.error' ,
		        thanks: '.thanks' ,
		        field: {
		            email: '[name=email]' ,
		            originid: '[name=email]' ,
		            submit: '.btn-submit'
		        }
		    } ,
		    texts: {
		        email: {
		            blank: 'Preencha o e-mail' ,
		            invalid: 'E-mail inválido'
		        } ,
		        server_error: 'Houve um erro ao cadastrar este email. Por favor, tente mais tarde.' ,
		        options: 'Por favor, escolha ao menos uma das opções de newsletter.',
		        subscriber: 'Para escolher a opção <strong>"Dicas do Editor"</strong> é necessário ser assinante da Folha.'
		    }
		};

		base.register_events(base, emailRegexp) ;
	}

	displayError( message ) {
	    this.$el.find( this.options.selectors.error ).text( message ) ;
	    this.$el.find( this.options.selectors.form.home ).addClass( 'invalid' ) ;
	}

	confirmation($formModal) {
	    $("#form_newsletter_modal").find( '[name=portugues]' ).prop("checked", this.$el.find( '[name=portugues]' ).prop("checked"));
	    $("#form_newsletter_modal").find( '[name=ingles]' ).prop("checked", this.$el.find( '[name=ingles]' ).prop("checked"));
	    $("#form_newsletter_modal").find( '[name=espanhol]' ).prop("checked", this.$el.find( '[name=espanhol]' ).prop("checked"));
	    $("#form_newsletter_modal").find( '[name=dica_editor]' ).prop("checked", this.$el.find( '[name=dica_editor]' ).prop("checked"));
	    $("#form_newsletter_modal").find( '[name=serafina]' ).prop("checked", this.$el.find( '[name=serafina]' ).prop("checked"));
	    $("#form_newsletter_modal").find( '[name=cultura_sp]' ).prop("checked", this.$el.find( '[name=cultura_sp]' ).prop("checked"));
	    $.fancybox.open($formModal, {
	        modal: true,
	        padding: 20,
	        width: '464',
	        afterClose : function() { 
	            $formModal.find( '.error' ).removeAttr('style').removeClass('subscriber');
	            $formModal.find( '.error p.login' ).removeAttr('style');
	        }   
	    }) ;
	}
	success( data ) {
	    var
	        $form = this.$el.find( this.options.selectors.form.home ) ,
	        $thanks = this.$el.find( this.options.selectors.thanks ) ;
	    
	    $form.hide() ;
	    $thanks.show() ;
	}
	error() {
	    var
	        $form = this.$el.find( this.options.selectors.form.home ) ,
	        $thanks = this.$el.find( this.options.selectors.thanks ) ;
	   
	    $form.hide() ;
	    $thanks
	        .find( '.text' )
	        .css( 'color' , '#f00' )
	        .text( this.options.texts.server_error ) ;
	    
	    $thanks.show() ;
	}
	process() {
	    var 
	        $formModal = location.pathname.indexOf('newsletter') <= 0 ? $( this.options.selectors.form.modal ) : $( this.options.selectors.form.home ),
	        news_array = [] ;
	    $formModal.find("input[type=checkbox]:checked").each(function() {
	        news_array.push($(this).val()) ;
	    }) ;

	    var
	        data = $.extend( true , {} , this.options.ajax , {
	            data: {
	                origin_id: news_array ,
	                email: this.$el.find( '[name=email]' ).val()
	            } ,
	            success: $.proxy( this.success , this ) ,
	            error: $.proxy( this.error , this )
	        } ) ;
	    $.ajax( data ) ;
	}
	register_events(base, emailRegexp) {
	    var
	        options = base.options,
	        $formHome = this.$el.find( base.options.selectors.form.home ) ,
	        $formModal = this.$el.find( base.options.selectors.form.modal ) ,
	        displayError = $.proxy( base.displayError , this ) ,
	        doConfirmation = $.proxy( base.confirmation , this ) ,
	        doPostConfirmation = $.proxy( base.process , this ) ;
	    $formHome.find( options.selectors.field.email ).bind( 'focus keyup' , function ( e ) {
	        if ( $formHome.hasClass( 'invalid' ) ) {
	            $formHome.removeClass( 'invalid' ) ;
	        }
	    } ) ;
	    $formHome.bind( 'submit' , function ( e ) {
	        var $email = $( options.selectors.field.email , this ) ;
	        e.preventDefault() ;
	        if ( $email.val().length === 0 ) {
	            displayError( options.texts.email.blank ) ;
	        } else if ( ! emailRegexp.full.test( $email.val() ) ) {
	            displayError( options.texts.email.invalid ) ;
	        } else {
	            if(location.pathname.indexOf('newsletter') <= 0){
	            	doConfirmation($formModal) ;
	            } else {
	     			doPostConfirmation();
	    		}
	        }
	    } ) ;
	    $formModal.bind( 'submit' , function ( e ) {
	        var $email = $( options.selectors.field.email , "#form_newsletter" ).val(),
	            isSubscriber = userfolstatus.subscriber;
	        e.preventDefault();
	        $formModal.find( '.error' ).removeAttr('style').removeClass('subscriber');
	        $formModal.find( '.error p.login' ).removeAttr('style');
	        if (this.portugues.checked == false && this.ingles.checked == false && this.espanhol.checked == false && this.dica_editor.checked == false && this.serafina.checked == false && this.cultura_sp.checked == false) {
	            $formModal.find( '.error' ).show().find('p.message').html(options.texts.options);
	        } else if(!isSubscriber && this.dica_editor.checked){ 
	            $formModal.find( '.error' ).addClass('subscriber').show().find('p.message').html(options.texts.subscriber);
	            $formModal.find( '.error p.login' ).show();
	        }else {
	            $.fancybox.close();
	            doPostConfirmation();
	        }
	     
	    } ) ;
	}
}