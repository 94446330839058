/**
 * Navegação
 *
 * @author	Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since	2015
 */
export class Navigation {

	/**
	 * Construtor
	 */
	constructor() {
	    this.last_size = $( "#main" ).width();
	    this.news = /\.shtml$/.test(location.pathname);
	    this.section = ! this.news;
	    this.staging = location.hostname.split(".",1)[0] == "staging";
	    this.current_section = location.pathname.split("/")[1];
	}

	/**
	 * Atualiza a página
	 */
	refresh(event) {
		event.preventDefault();
		location.reload();
	}

	/**
	 * Retorna a página atual
	 *
	 * @return {String}
	 */
	get_current_section(){
		return this.current_section;
	}

	/**
	 * Retorna se é uma editoria ou não
	 *
	 * @return {Boolean} Verdadeiro se for uma editoria
	 */
	is_section() {
		return this.section;
	}

	/**
	 * Retorna se é uma página de notícia ou não.
	 *
	 * @return {Boolean} Verdadeiro se for uma notícia.
	 */
	is_news() {
		return this.news;
	}

	/**
	 * Verifica se é staging
	 *
	 * @return {Boolean} Verdadeiro se sim
	 */
	is_staging() {
		return this.staging;
	}

	/**
	 * Depois que realizar o resize da tela garante que realmente aconteceu o resize
	 *
	 * @return void
	 */
	after_resize(){
	 	if ( this.last_size != $( "#main" ).width() ) {
	 		$( window ).trigger( "folha.resize" );
	 		this.last_size = $( "#main" ).width();
	 	}
	}

	/**
	 * Registra os eventos
	 */
	registry_events(){
		$(window).on('resize', $.proxy( this.after_resize, this));
		$(".update").on("click", $.proxy( this.refresh, this ));
	}
}