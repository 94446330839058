/**
 * Impeachment Scoreboard
 *
 * @author  Evandro Santos - (ev.santos) <evandro.santos@grupofolha.com.br>
 * @since   2016
 */

export class Impeachment {
    constructor() {
        this.opts = {
            cacheResolution: 1000,
            baseUrl: '//www1.folha.uol.com.br/interacoes/2016/04/congresso/chamber_impeachment_simple.json',
            ajax: {
                url: '' ,
                dataType: 'jsonp',
                jsonpCallback: 'chamber',
                cache: true
            },
            votes: {
                maximumVotes: 513,
                favorVotesRequired: 342
            },
            selectors: {
                box: {
                    scoreboard: '.placar-camara',
                } ,
                side: {
                    pro: {
                        main: '.result-content-pro',
                        bar: '.result-content-bar-pro'
                    },
                    against: {
                        main: '.result-content-against',
                        bar: '.result-content-bar-against'
                    },
                    missed: {
                        main: '.result-content-missed',
                        bar: '.result-content-bar-missed',
                        span: '.text.missed',
                        text: 'Indefinidos: <div class="counter">0</div>; '
                    },
                    absteined: {
                        main: '.result-content-missed',
                        bar: '.result-content-bar-missed',
                        span: '.text.absteined',
                        text: 'Indefinidos: <div class="counter">0</div>; '
                    },
                    not_answer: {
                        main: '.result-content-not_answer',
                        span: '.text.not_answer',
                        text: 'Não respondeu: <div class="counter">0</div>; '
                    },
                    not_declared: {
                        main: '.result-content-not_declared',
                        span: '.text.not_declared',
                        text: 'Não declarado: <div class="counter">0</div>; '
                    },
                    not_found: {
                        main: '.result-content-not_found',
                        span: '.text.not_found',
                        text: 'Não encontrado: <div class="counter">0</div>'
                    }
                },
                counter: '.counter',
                kicker: {
                    element: '.kicker',
                    text: 'Posição dos deputados sobre o impeachment'
                },
                total: '.result-content-total-missing'
            }
        } ;

        this.$el = this.opts.selectors.box.scoreboard ;
        
        if ( ! ( this.$el instanceof $ ) ) {
            this.$el = $( this.$el ) ;
        }
                 
        if(this.$el.length > 0){
            this.process() ;
                        
            if(new Date().getTime() > 1460905200000 && new Date().getTime() < 1460948400000){
                setInterval( () => { this.process() } , 30000 );
            }
        }
    }
    
    success( data ) {
        let results = data ;
        
        if( results.votes.total > 0 ) {
            this.mount( results.votes ) ;
            this.mountBar( results.votes ) ;
        } else {
            this.mountTendency( results.tendency );
            this.mountBar( results.tendency ) ;
        }
    }
    
    error( jqXHR , textStatus, errorThrown ) {
		console.log( "Error ajax" ) ;
		console.log( jqXHR , textStatus, errorThrown ) ;
        
        $( this.opts.selectors.box.scoreboard ).remove() ;
	}
    
    mount( data ) {
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.pro.main +' '+  this.opts.selectors.counter ).html( data.favor ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.against.main +' '+  this.opts.selectors.counter ).html( data.against ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.missed.main +' '+  this.opts.selectors.counter ).html( data.missed ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.absteined.span +' '+  this.opts.selectors.counter ).html( data.absteined ) ;
    }

    mountTendency( data ) {
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.missed.main ).html( '<span class="text missed">Indecisos: <div class="counter">0</div>; </span><span class="text not_answer">Não responderam: <div class="counter">0</div>; </span><span class="text not_declared">Não declaram: <div class="counter">0</div>; </span><span class="text not_found">Não encontrados: <div class="counter">0</div></span>' ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.missed.span +' '+  this.opts.selectors.counter).html( data.missed ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.not_answer.span +' '+  this.opts.selectors.counter).html( data.not_answer ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.not_declared.span +' '+  this.opts.selectors.counter).html( data.not_declared ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.not_found.span +' '+  this.opts.selectors.counter).html( data.not_found ) ;

        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.pro.main +' '+  this.opts.selectors.counter ).html( data.favor ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.against.main +' '+  this.opts.selectors.counter ).html( data.against ) ;
    }
    
    mountBar( data ) {
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.pro.bar ).css( { width: this.getPercent( data.favor )+'%' } ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.against.bar ).css( { width: this.getPercent( data.against )+'%' } ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.missed.bar ).css( { width: this.getPercentMissing( data.favor, data.against )+'%' } ) ;
    }
    
    sumMissedVotes( missed , absteined ) {
        return missed + absteined ;
    }
		
    totalVotes (total) {
         return this.opts.votes.favorVotesRequired - total > 0 ? this.opts.votes.favorVotesRequired - total : total - this.opts.votes.favorVotesRequired ; ; 
    }
    
    getPercent(data) {
        return parseFloat( ( ( data / this.opts.votes.maximumVotes ) * 100 ).toFixed( 2 ) ) ;
    }
    
    getPercentMissing( pro , against ) {
        return ( 100 - ( this.getPercent( pro ) + this.getPercent( against ) ) ) ;
    }
    
    process() {
        let bust = Math.floor((new Date).getTime() / this.opts.cacheResolution)
        this.opts.ajax.url = this.opts.baseUrl + '?_' + bust;

        let promise = $.ajax( this.opts.ajax ) ;
        
        promise.done( data => {
            this.success( data ) ;
        } ) ;
        
        promise.fail ( this.error ) ;
    }
}