/**
 * Soundcloud
 *
 * @author	Evandro Santos - (ev.santos) <evandro.santos@grupofolha.com.br>
 * @since	2015
 */
export class Soundcloud {
	/**
	 * Construtor
	 */
	constructor() {
		var base = this ;

		$( "p.soundcloud" ).each( function() {
			base.mount( $( this ) ) ;
		} ) ;
	}

	/**
	 * Replace p.instagram > a
	 * em iframe
	 */
	mount( $el ) {
		let url = $el.find( 'a' ).attr( 'href' );

		$el.html(
			$( "<iframe \/>" )
				.attr( 'src' , url.replace( /^/ , 'https://w.soundcloud.com/player/?url=' )
				.concat( '&amp;auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&visual=true' ) )
				.attr( { 
					'width': '100%' ,
					'height': '150' ,
				} )
				.css( {
					'border': '0',
					'overflow': 'hidden',
				} ) 
		) ;
	}
}