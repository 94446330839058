/**
 * Championship Round - Paulista
 *
 * @author  Caio Nunes - <caio.nunes@grupofolha.com.br>
 * @since   2016
 */

export class ChampionshipRoundPaulista {
    constructor() {
        this.championshiproundpaulista();
    }
    championshiproundpaulista(){
    $.fn.championship_paulista_round = function ( ) { 
    var round_id = this[0].id,
        championshipPaulista = this.id = {},
        currentRound = $.trim( $( this[0] ).data( 'round' ) ),
        currentStage,
        championshipName = championshipName,
        picked_data = [];

        // Status como nome
        function convert_status( status ) {
            switch( status ){
                case "1":
                    return "Em Andamento";
                    break;
                case "3":
                    return "Adiado";
                    break;
                case "2":
                    return "Encerrado";
                    break;
                default:
                    return "";
            }
        };
        function to_slug( name ) { //Nome em slug para imagem
            return name.toLowerCase()
                .replace( /\u00E1|\u00E2|\u00E3/g , 'a' )
                .replace( /\u00E9|\u00EA|\u00EB/g , 'e' )
                .replace( /\u00ED/g , 'i' )
                .replace( /\u00F3|\u00F4|\u00F5/g , 'o' )
                .replace( /\u00FA|\u00FC/g , 'u' )
                .replace( /\u00E7/g , 'c' )
                .replace( /[\s\-]+/g , '_' )
                .replace( /\W/g , '' ) ;
        };

        // Orderna partidas colocando as encerradas ou adiadas por ultimo
        function order_matches( data ) {
            
            for ( var i = 0 , matches = [] , matches_end = [],  len = data.matches.length ; i < len ; i++ ) {
                if( data.matches[i].status != "3" && data.matches[i].status != "2" ){
                    matches.push( data.matches[i] );
                } else{
                    matches_end.push( data.matches[i] );
                }
            }
            data.matches = matches.concat( matches_end ) ;

            return data ;
        };
        
        function ChampionshipController( ext ) {
            for ( var opt in ext ) {
                this[ opt ] = ext[ opt ] ;
            }
        };
        
    ChampionshipController.prototype.config = null ;
    ChampionshipController.prototype.container = function () {
        return $( this.config.selector ) ;
    } ;
    ChampionshipController.prototype.test = function () {
        return this.container().length === 1 ;
    } ;
    ChampionshipController.prototype.get_src = function () {
        return $.trim( this.container().data( 'src' ) ) ;
    } ;
    ChampionshipController.prototype.get_name = function () {
        var arrName = this.container().data( 'src' ).split( '/' );
        if( championshipName != undefined )
            return championshipName
        
        for( var i = 0; i < Object.keys( arrName ).length; i++ ){
            if( arrName[i] === "virtual" ){
                var name_champ = arrName[i-1];
                name_champ = name_champ.replace( /serie/g, 'série' );
                name_champ = name_champ.replace( /-|_/g, ' ' );
            }
        }
        return name_champ
    } ;
    ChampionshipController.prototype.set_type = function () {
        var type = $.trim( this.container().data( 'type' ) ) ;
        if( type == "auto-refresh" ) { this.requestInterval( this ); }
    } ;
    ChampionshipController.prototype.set_qty = function () {
        return $.trim( this.container().data( 'qty' ) ) ;
    } ;
    ChampionshipController.prototype.requestInterval = function ( object ) {
        function controllerRequest(){
            object.request();
        } setInterval( controllerRequest , 22000);
    }
    ChampionshipController.prototype.render = function ( data ) {
        // filter
        data = this.filter( data ) ;

        if(data === false)
            return false

        // mount
        this.container().html( this.mount(data) ) ;

    } ;
    ChampionshipController.prototype.mount = function ( data ) {
        
        var matcheObj = data.rodada.matches,
            html = '';
        html += '<h3>' + data.name + '</h3>';
        html += '<div class="content">';
        html += '   <ul class="game-list">';
        for( var i = 0; i < matcheObj.length; i++){
                html += '<li class="game">';
                html +=     '<span class="home team">';
                html +=         '<img class="flag" src="//f.i.uol.com.br/folha/esporte/futebol/escudo/2015/' + to_slug( matcheObj[i].teams[0].name ) + '-100x100.png" alt="' + matcheObj[i].teams[0].name + ' (Brasão)">';
                html +=         '<span class="name">' + matcheObj[i].teams[0].name + '</span>';
                html +=     '</span>';
                html +=     '<span class="score">';
                html +=         '<div class="info">' + convert_status( matcheObj[i].status ) + '</div>'
                html +=         '<span class="team-a">' + matcheObj[i].teams[0].score + '</span><span class="vs">x</span><span class="team-b">' + matcheObj[i].teams[1].score + '</span>'
                html +=     '</span>';
                html +=     '<span class="away team">';
                html +=         '<img class="flag" src="//f.i.uol.com.br/folha/esporte/futebol/escudo/2015/' + to_slug( matcheObj[i].teams[1].name ) + '-100x100.png" alt="' + matcheObj[i].teams[1].name + ' (Brasão)">';
                html +=         '<span class="name">' + matcheObj[i].teams[1].name + '</span>';
                html +=     '</span>';
                html += '</li>';
        }
        html += '</ul>';
        html += '</div>';

        return html
    }
    ChampionshipController.prototype.filter = function ( data ) {
        return data ; 
    } ;

    ChampionshipController.prototype.request = function () {
        $.ajax( {
            url: "https://m.folha.uol.com.br" + this.get_src(),
            cache: false , 
            dataType: "jsonp" ,
            jsonpCallback: this.config.callback ,
            success: $.proxy( this.render , this ),
            error: function(xhr, textStatus, errorThrown ) {
                    console.log(xhr + " , " + textStatus + " , " + errorThrown);
                }
        })
    } ;

    ChampionshipController.prototype.round_robin = function ( data ) {

            var limitRound = this.set_qty();

            currentRound > 18 ? currentRound = 17 : currentRound = currentRound - 1;

            if( currentRound < 12 ){
                currentStage = 0;
            } else if( currentRound < 13 || currentRound < 14 ) { 
                currentStage = 1;
                currentRound = currentRound - 13 + 1; //Quartas
            } else if( currentRound < 15 || currentRound < 16 ) { 
                currentStage = 2;
                currentRound = currentRound - 15 + 1; // Semi
            } else if( currentRound < 17 || currentRound < 18 ) { 
                currentStage = 3;
                currentRound = currentRound - 17 + 1; // Finais
            }
            
            if( typeof championship_round_show != 'undefined' ){
            var picked_matches = championship_round_show.matches,
                data_macthes = data[currentStage][currentRound].matches;

            picked_data = [];

            for (var k in picked_matches) {  
                for (var j in data_macthes) {  
                    if( isNaN(j) || isNaN(k) )
                        break
                    if (picked_matches[k].teams[0] == data_macthes[j].teams[0].code){
                        if (picked_matches[k].teams[1] == data_macthes[j].teams[1].code){
                           picked_data.push(data_macthes[j]);
                        }
                    }
                }
            }
            }

            if( limitRound != '' && !isNaN( limitRound ) ){
                if( data[currentStage][currentRound].matches.length > limitRound ){
                    var toSlice = data[currentStage][currentRound].matches.length - limitRound;
                    data[currentStage][currentRound].matches.splice( limitRound,toSlice );
                }
            }

        return data ; 
    } ;
    ChampionshipController.prototype.init = function () {
        if ( !this.config.selector ) {
            throw new Error( '[ChampionshipController] Container selector not defined.' ) ;
        }
        if ( !this.config.callback ) {
            throw new Error( '[ChampionshipController] jsonp callback not defined.' ) ;
        }
        this.test();
        this.request(); 
        this.set_type();
    } ;

    championshipPaulista.rounds = new ChampionshipController( {
        config: {
            selector: '.championship-paulista-round#' + round_id + '[data-src]' ,
            callback: 'rodada'
        } ,
        filter: function ( data ) {
           
            switch( round_id ){
                case "serie-a":
                    this.round_robin( data );
                break;
                case "serie-b":
                    this.round_robin( data );
                break;
                default:
                    console.log( '[ChampionshipController] Tipo de Campeonato não suportado;' );
                    return false
            }

            if( picked_data.length ){
                data[currentStage][currentRound].matches = picked_data;
            } else{
                order_matches( data[currentStage][currentRound] );  
            }

            return {
                rodada: data[currentStage][currentRound],
                name: this.get_name()
            } ;
        }
    } ) ;

    championshipPaulista.rounds.init();

    };

    $( '.championship-paulista-round ' ).each(function(){
        $( this ).championship_paulista_round();
    })
     
    }
}