/**
 * Modal da Arte
 *
 * @author	Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since	2015
 */
export class ModalArt {
	/**
	 * Abre o modal
	 */
	open(event){
		event.preventDefault();
		console.log(this.constructor.name, this.open.name);

		$('#uber_blackout').one('click', () => {
			this.hide( this.position_top )

		} ).html($('#modal_base').html())
			.show()
			.find(".image")
			.html(this.build_iframe(event.currentTarget))
			.parent()
			.addClass('modal');

		$( '#main' ).hide();
	}

	/**
	 * Monta o iframe de acordo com o tipo de filho presente.
	 */
	build_iframe(currentTarget) {
		var child = this.child_strategy(currentTarget);

		return $('<iframe>')
				.attr('src', child.attr('data-art'))
				.height($(window).height());
	}

	/**
	 * Muda o filho
	 */
	child_strategy(currentTarget) {
		var span = $(currentTarget).find('span');
		return span.length > 0 ? span : $(currentTarget).find('img');
	}

	/**
	 * Remove o modal
	 */
	hide( position ){
		$( '#uber_blackout' ).hide() ;
		$( '#main' ).show() ;

		if ( !isNaN(parseFloat(position)) && isFinite(position) ) {
			$( window ).scrollTop( position ) ;
		}
	}

	/**
	 * Eventos
	 */
	registry_events(){
		let base = this ;
		$('p.html5Art a').on( 'click' , function( e ) {
			let el = $( this ) ;
			base.position_top = el.position().top ;
			base.open( e ) ;
		} ) ;
	}
}
