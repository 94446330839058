/**
 * Barra do UOL
 *
 * @author	Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since	2015
 */
export class UOL {
	/**
	 * Construtor
	 */
	constructor() {
		this.app_uol = false;
		this.app_ios = false;
		this.app_android = false;
		this.check_referer_uol().easter_egg().barra();
		this.check_referer_app().easter_egg().barra();
	}

	/**
	 * Formata informações de debug
	 */
	get_debug(){
		if ( !folha ) return "";

		return `
			HTTP_REFERER : ${folha.HTTP_REFERER} <br>
			HTTP_X_REFERER : ${folha.HTTP_X_REFERER} <br>
			HTTP_X_NAMESPACE : ${folha.HTTP_X_NAMESPACE} <br>
		`;
	}

	/**
	 * Verifica se o usuário veio redirecionado do uol
	 */
	check_referer_uol() {
		if ( !folha ) return this;

		var referer = folha.HTTP_REFERER ? folha.HTTP_REFERER : "" ;
		var xreferer = folha.HTTP_X_REFERER ? folha.HTTP_X_REFERER : "" ;

		referer = referer ? referer : xreferer ;

		// Caso venha do APP do UOL
		if ( folha.HTTP_X_NAMESPACE && folha.HTTP_X_NAMESPACE == "br.com.uol.m" ) {
			this.app_uol = true ;
		}

		// caso o referer ou x-referer seja apenas uol provavelmente é o app
		var parser = document.createElement('a');
		parser.href = referer;
		if ( parser.hostname == "uol.com.br" ) {
			this.app_uol = true ;
		}

		return this;
	}

	/**
	 * Verifica se o usuário veio redirecionado do app IOS
	 */
	check_referer_app() {
		if ( !folha ) return this;

		var referer = folha.HTTP_REFERER ? folha.HTTP_REFERER : "" ;
		var xreferer = folha.HTTP_X_REFERER ? folha.HTTP_X_REFERER : "" ;

		referer = referer ? referer : xreferer ;

		// Caso venha do APP IOS
		if ( folha.HTTP_X_NAMESPACE && folha.HTTP_X_NAMESPACE == "folhaiosapp" ) {
			this.app_ios = true ;
		}

		// Caso venha do APP ANDROID
		if ( folha.HTTP_X_NAMESPACE && folha.HTTP_X_NAMESPACE == "folhaandroidapp" ) {
			this.app_android = true ;
		}

		// caso o referer ou x-referer seja folhaiosapp/folhaandroidapp provavelmente é o app
		var parser = document.createElement('a');
		parser.href = referer;
		if ( parser.hostname == "folhaiosapp" ) {
			this.app_ios = true ;
		} else if ( parser.hostname == "folhaandroidapp" ) {
			this.app_android = true ;
		}

		return this;
	}

	/**
	 * Mostra informações de debug
	 */
	show_information(){
		console.log(this.constructor.name, this.show_information.name);

		$('#uber_blackout').one('click', this.hide)
			.html(this.get_debug())
			.show()
			.addClass('modal')
			.find('pre:first').css('text-align', 'left');

		$( '#main' ).hide();
	}

	/**
	 * Adiciona um evento de clique longo
	 */
	easter_egg(){
		console.log(this.constructor.name, this.easter_egg.name);

		$('#view-view').on(
			'longpress',
			'.copyright > p',
			$.proxy( this.show_information, this )
		);
		return this;
	}

	/**
	 * Remove o modal
	 */
	hide(){
		$('#uber_blackout').hide();
		$('#main').show();
	}

	/**
	 * Adiciona ou remove a barra do uol
	 */
	barra(){
		if ( this.app_uol ) {
			$('.barrauol').remove();
			$('#nav-local').css('top', "60px").height('+=10');
			$('#extramenu').css('top', "60px");

			return this;
		}

		if( this.app_ios || this.app_android ){
			$('.barrauol').remove();
			$('header#bar').remove();
			$('.ad-top-container').remove();

			return this;
		}

		$('.barrauol').show();
		$('#nav-local').css('top', "100px").height('-=30');
		$('#extramenu').css('top', "60px");
			
		return this;
	}

	unpin_bar() {
		var top_offset = $(window).scrollTop();
		var barrauol_default_height = 40;

		if( this.app_ios || this.app_android ){
			return;
		}

		if (top_offset >= barrauol_default_height) {
			$('#bar').css({ 'position': 'fixed', '-webkit-transform': 'translate3d(0,0,0)' });
			$('#main').css('padding-top', '98px');
			$('#nav-local').css('top', "60px");
			$('#extramenu').css('top', "60px");

		} else if (top_offset < barrauol_default_height) {
			$('#bar').css('position', 'relative');
			$('#main').removeAttr('style');
			if ($('.barrauol').length > 0){
				$('#nav-local').css('top', "100px");
			}
			else
			{
				$('#nav-local').css('top', "60px");
			}
			$('#extramenu').css('top', "60px");
		}

		return this;
	}

	registry_events(){
		$(window).on('scroll', $.proxy( this.unpin_bar, this ));
	}

}
