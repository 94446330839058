/**
 * Audio
 *
 * @author	Anderson Felix - (a.felix) <anderson.felix@grupofolha.com.br>
 * @since	2015
 */
export class Audio {
	/**
	 * Construtor
	 */
	constructor() {
		var base = this ;

		$( "p.audioPlayer" ).each( function() {
			base.mount( $( this ) ) ;
		} ) ;
	}

	/**
	 * Obt? extensão do arquivo pela URL
	 */
	get_extension( url ) {
		let pattern = /\.([\w]{3})$/gi , ext = "" ;

		if ( url.match( pattern ) ) {
			ext = RegExp.$1 ;
		}

		return "audio/" + ext ;
	}

	/**
	 * Mount audio tag
	 */
	mount( $el ) {
		let url = $el.find( "a" ).attr( "href" ) ,
			ext = this.get_extension( url ) ,

			audio = `<audio controls="controls"><source src="${url}" type="${ext}"></audio>` ;

		$el.after( audio ).remove() ;
	}
}