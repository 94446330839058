/**
 * Twitter
 *
 * @author	Evandro Santos - (ev.santos) <evandro.santos@grupofolha.com.br>
 * @since	2015
 */
export class Twitter {
	/**
	 * Construtor
	 */
	constructor() {
		setTimeout(function () {
			$( "p.twitter, p.tweet" ).each( function() {
				var $el = $(this);
				var id = $el.find( 'a' ).attr( 'href' ).replace( /^https?:\/\/(www\.)?twitter\.com\/([\w]+)\/status\/([\d]+)/gi , '$3' ) ;

				$.ajax( {
					cache: true ,
					url: 'https://api.twitter.com/1/statuses/oembed.json' ,
					data: {
						align: 'center' ,
						id: id ,
						maxwidth: 620
					} ,
					dataType: 'jsonp' ,
					success: function ( data ) {
						$el.html( data.html ) ;
					}
				} ) ;
				
			} ) ;
		}, 1000 );
	}
}