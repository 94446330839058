/**
 * Vídeo
 *
 * @author  Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since   2015
 */
export class Video {

    /**
     * Construtor
     */
    constructor() {
        this.script_youtube_added = false;
        this.youtube();
    }

    /**
	 * Realiza o play do vídeo
	 */
	play(event){
		event.preventDefault();

		var parent = $(event.currentTarget).parent();
		var video_player_list = parent.find("video");

		// Se encontrar algum player de vídeo
		if ( video_player_list.length ) {
			$(event.currentTarget).hide();
			video_player_list.show();
			video_player_list.get(0).play();
		}

		video_player_list = parent.find("div.youtube-player");

		// Se encontrar algum player de vídeo
		if ( video_player_list.length ) {
			var player = $(video_player_list.get(0));
			var video_id = player.attr( "data-src" );
			var div_id = "video-" + video_id;
			var height_area = $(player).parent().innerHeight() ;

			// Cria o player do youtube
			player.attr( "id" , div_id );
			new YT.Player(div_id, {
	          videoId: video_id,
	          height: height_area,
	          width: "100%",
	          events: {
	            'onReady': this.play_video_youtube
	          },
	          origin: location.origin
		        });

			$(event.currentTarget).hide();
			video_player_list.show();
		}
	}

	/**
	 * Adiciona o script do player do youtube
	 *
	 * @see https://developers.google.com/youtube/iframe_api_reference
	 */
	youtube(){
		if ( this.script_youtube_added ) return;

		if ( ! $("#view-view div.youtube-player").length ) return;

		this.script_youtube_added = true;

		var tag = document.createElement('script');
			tag.src = "https://www.youtube.com/iframe_api";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	}

	/**
	 * Dá o play no vídeo do youtube
	 */
	play_video_youtube(event){
		event.target.setVolume(100);
		event.target.playVideo();
	}

	/**
	 * Registra os eventos
	 */
	registry_events(){
		$(window).on('resize', $.proxy( this.after_resize, this));
		$(".update").on("click", $.proxy( this.refresh, this ));

		$('#view-view').on('click', 'a.video-player', $.proxy( this.play, this ));
	}
}