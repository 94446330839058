/**
 * Impeachment Scoreboard
 *
 * @author  Evandro Santos - (ev.santos) <evandro.santos@grupofolha.com.br>
 * @since   2016
 */

export class ImpeachmentSenateFinal {
    constructor() {
        this.opts = {
            cacheResolution: 1000,
            baseUrl: {
                www: '//www.folha.uol.com.br/virtual/5.4/common/senate_impeachment-final_full.js',
                www1: '//www1.folha.uol.com.br/interacoes/2016/04/congresso/senate_impeachment-final_full.json'
            },
            memcachedUrl: '//senado.folha.com.br/senado.php?file=senate_impeachment_full.json',
            ajax: {
                url: '',
                dataType: 'jsonp',
                jsonpCallback: 'scores',
                cache: true
            },
            votes: {
                maximumVotes: 81,
                favorVotesRequired: 41
            },
            selectors: {
                box: {
                    scoreboard: '.placar-final-senado',
                } ,
                limiter: {
                    main: '.result-content-bar-limiter',
                    span: 'text',
                    text: 'S&#227;o necess&#225;rios 54'
                } ,
                side: {
                    pro: {
                        main: '.result-content-pro',
                        bar: '.result-content-bar-pro'
                    },
                    against: {
                        main: '.result-content-against',
                        bar: '.result-content-bar-against'
                    },
                    missed: {
                        main: '.result-content-missed',
                        bar: '.result-content-bar-missed',
                        span: '.text.missed',
                        text: 'Indefinidos: <div class="counter">0</div>; '
                    },
                    absteined: {
                        main: '.result-content-missed',
                        bar: '.result-content-bar-missed',
                        span: '.text.absteined',
                        text: 'Indefinidos: <div class="counter">0</div>; '
                    },
                    not_answer: {
                        main: '.result-content-not_answer',
                        span: '.text.not_answer',
                        text: 'Não respondeu: <div class="counter">0</div>; '
                    },
                    not_declared: {
                        main: '.result-content-not_declared',
                        span: '.text.not_declared',
                        text: 'Não declarado: <div class="counter">0</div>; '
                    },
                    not_found: {
                        main: '.result-content-not_found',
                        span: '.text.not_found',
                        text: 'Não encontrado: <div class="counter">0</div>'
                    }
                },
                counter: '.counter',
                kicker: {
                    element: '.kicker',
                    text: 'Posição dos deputados sobre o impeachment'
                },
                total: '.result-content-total-missing'
            }
        } ;

        this.$el = this.opts.selectors.box.scoreboard ;
        
        if ( ! ( this.$el instanceof $ ) ) {
            this.$el = $( this.$el ) ;
        }
        
        if(this.$el.length > 0){
            var limiterText = $( this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.limiter.main + ' .text' ).html().replace('${limiter-text}', this.opts.selectors.limiter.text) ;
            $( this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.limiter.main + ' .text' ).html(limiterText) ;

            if(folha_impeachment.startpooling || folha_impeachment.active ) {
                $( this.opts.selectors.box.scoreboard + ' '  + this.opts.selectors.limiter.main).css({ 'background-color': '#000', 'height': '30px' }) ;
                $( this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.limiter.main + ' .text' ).css({'top': '-20px'}) ;
            }

            if( folha_impeachment.active && typeof folha_impeachment.data.impeachment_trials !== "undefined" ) {
                
                setTimeout( () => {
                    this.mount( folha_impeachment.data.impeachment_trials ) ;
                    this.mountBar( folha_impeachment.data.impeachment_trials ) ;
                } , 500 ) ;

            } else {

                setTimeout( () => { this.process() } , 500 );
                            
                if(folha_impeachment.startpooling){
                    setInterval( () => { this.process() } , 30000 );
                }

            }
        }
    }
    
    success( data ) {
        let results = data.results ;

        this.opts.votes.maximumVotes = typeof folha_impeachment.data !== "undefined" && folha_impeachment.active ? folha_impeachment.data.impeachment_trials.presence : this.opts.votes.maximumVotes ;
        
        if( folha_impeachment.startpooling ) {
            this.mount( results.votes ) ;
            this.mountBar( results.votes ) ;
        } else {
            this.mountTendency( results.tendency );
            this.mountBar( results.tendency ) ;
        }
    }
    
    error( jqXHR , textStatus, errorThrown ) {
        console.log( "Error ajax" ) ;
        console.log( jqXHR , textStatus, errorThrown ) ;
        
        $( this.opts.selectors.box.scoreboard ).remove() ;
    }
    
    mount( data ) {
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.pro.main +' '+  this.opts.selectors.counter ).html( data.favor ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.against.main +' '+  this.opts.selectors.counter ).html( data.against ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.missed.main +' '+  this.opts.selectors.counter ).html( data.missed ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.absteined.span +' '+  this.opts.selectors.counter ).html( data.absteined ) ;

        if(folha_impeachment.disable_link){
            $( this.opts.selectors.box.scoreboard ).find('a').attr('href', '#');
        }
    }

    mountTendency( data ) {
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.missed.span ).html( '<span class="text missed">Indefinidos: <span class="counter">0</span> </span>' );
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.missed.span + ' ' +  this.opts.selectors.counter).html( this.sumMissedVotes( data.missed , data.absteined , data.absent , data.not_answer , data.not_declared , data.not_found ) ) ;

        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.pro.main +' '+  this.opts.selectors.counter ).html( data.favor ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.against.main +' '+  this.opts.selectors.counter ).html( data.against ) ;
    }
    
    mountBar( data ) {
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.pro.bar ).css( { width: this.getPercent( data.favor )+'%' } ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.against.bar ).css( { width: this.getPercent( data.against )+'%' } ) ;
        $(  this.opts.selectors.box.scoreboard + ' ' + this.opts.selectors.side.missed.bar ).css( { width: this.getPercentMissing( data.favor, data.against )+'%' } ) ;
    }
    
    sumMissedVotes( missed , absteined , absent , not_answer , not_declared , not_found ) {
        return missed + absteined + absent + not_answer + not_declared + not_found ;
    }
        
    totalVotes (total) {
        return this.opts.votes.favorVotesRequired - total > 0 ? this.opts.votes.favorVotesRequired - total : total - this.opts.votes.favorVotesRequired ;
    }
    
    getPercent(data) {
        return parseFloat( ( ( data / this.opts.votes.maximumVotes ) * 100 ).toFixed( 2 ) ) ;
    }
    
    getPercentMissing( pro , against ) {
        return ( 100 - ( this.getPercent( pro ) + this.getPercent( against ) ) ) ;
    }
    
    process() {
        let bust = Math.floor((new Date).getTime() / this.opts.cacheResolution)
        this.opts.ajax.url = ! folha_impeachment.safeload ? this.opts.memcachedUrl : folha_impeachment.origin ? this.opts.baseUrl.www : this.opts.baseUrl.www1 ;

        let promise = $.ajax( this.opts.ajax ) ;
        
        promise.done( data => {
            this.success( data ) ;
        } ) ; 

        promise.fail ( 
            this.processSafeLoad( this.opts ) 
        ) ;
    }

    processSafeLoad( opts ) {
        this.opts = opts ; 
        
        let bust = Math.floor((new Date).getTime() / this.opts.cacheResolution)
        this.opts.ajax.url = folha_impeachment.origin ? this.opts.baseUrl.www : this.opts.baseUrl.www1 ;

        let promise = $.ajax( this.opts.ajax ) ;
        
        promise.done( data => {
            this.success( data ) ;
        } ) ;

        promise.fail ( this.error ) ;
    }
}