/**
 * Document Cloud
 * oEmbed -> https://www.documentcloud.org/help/api#guidelines
 *
 */
export class DocumentCloud {
	/**
	 * Construtor
	 */
	constructor() {
		var base = this ;

		$( "p.documentcloud" ).each( function() {
			base.mount( $( this ) ) ;
		} ) ;
	}

	mount( $el ) {
		$.ajax( {
			url : "https://www.documentcloud.org/api/oembed.json" ,
			data : {
				"url" : $el.find( "a" ).attr( "href" ) ,
				"responsive" : true
			} ,			
			success : function( data ) {
				if ( data.hasOwnProperty( "html" ) ) {
					$el.after( data.html ) ;
					$el.remove() ;
				}
			}
		} ) ;
	}
}