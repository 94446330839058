/**
 * Modal - Imagem antes e depois
 *
 * @author	Filipe Donadio - (f.donadio) <filipe.donadio@grupofolha.com.br>
 * @since	2015
 */
export class ModalBeforeAfter {

	// Monta o HTML das imagens dentro da matéria
	build_images() {
		var comparaTotal = $( ".fotoAB" ).length ;
		if ( comparaTotal >= 2 && comparaTotal % 2 === 0 ) {
			for (var atual = 0; atual < comparaTotal; atual++ ) {
				var nomeID = "imagediffer" + atual/2 ,
				elem1 = $( ".fotoAB" ).eq( atual ) ,
				elem2 = $( ".fotoAB" ).eq( atual + 1 ) ,
				cred = "" ,
				cred1 = "" ,
				cred2 = "" ,
				DOM = $.parseHTML( '<table class="articleGraphic" id="' + nomeID + '"><tr><td class="articleGraphicCredit"></td></tr><tr><td class="image-differ-preload" data-load="0"><div class="box-image-differ"></div></td></tr><tr><td class="articleGraphicCaption"></td></tr></table>' ) ; 
				cred1 = $( elem1 ).find( ".articleGraphicCredit" ).html() ;
				cred2 = $( elem2 ).find( ".articleGraphicCredit" ).html() ;
				if ( cred1.length > 1 && cred2.length > 1 ) {
					cred = cred1 + " e " + cred2 ;
				}
				else {
					cred = cred1 + cred2 ;
				}
				$( DOM ).find( ".articleGraphicCredit" ).append( cred ) ;
				$( DOM ).find( ".articleGraphicCaption" ).append( $( elem1 ).find( ".articleGraphicCaption" ).html() ) ;
				$( elem1 ).attr( "data-relid" , nomeID ).hide().before( DOM ) ;
				$( elem2 ).attr( "data-relid" , nomeID ).hide() ;
				$( DOM ).find( ".box-image-differ" ).html( [
					'<a href="#',
					nomeID,
					'" class="antes-depois"><img src="',
					$( elem1 ).find( "img" ).attr( "src" ) ,
					'" ></a>' ,
					'<div class="box-image-differ-ui"><div class="box"><a href="#',
					nomeID,
					'" class="antes-depois"><img style="display: none;" src="',
					$( elem2 ).find( "img" ).attr( "src" ) ,
					'" >',
					'<div class="image-differ" style="background-image: url(' ,
					$( elem2 ).find( "img" ).attr( "src" ) ,
					');"></div></a>' ,
					'</div></div>'
				].join("") ) ;
				atual++;
			}
		}
	}

	// Monta o slider modal
	build_slider(sliderId) {
		var images = [];
		$(sliderId).find("img").each(function() {
			images.push($(this).attr("src"));
		});

		var html = '<div class="modal-image-slider box-image-differ"><img style="width: 100%" src="'+images[0]+'"><div class="box-image-differ-ui ui-resizable"><div class="box"><img src="'+images[1]+'"></div><div class="ui-resizable-handle ui-resizable-e" style="z-index: 90; height: 100%;"></div></div></div>';
		
		return html;
	}

	// Abre o modal com o slider
	open(event) {
		event.preventDefault();
		event.stopPropagation();
		var sliderId = $(event.target).parent().attr('href');
		//
		$('#uber_blackout')
			.html($('#modal_base').html())
			.show()
			.find(".image")
			.html(this.build_slider(sliderId))
			.parent()
			.addClass('modal')
			.addClass('modal-image-container');

		$( '#main' ).hide();

		// Evento botão close
		$('#uber_blackout .close').one('click', this.hide);
		
		var w = $(window).width();
		var h = $(window).height();

		$( "#uber_blackout .box-image-differ" ).width( w ).height( h ).find( ".ui-resizable-handle" ).on('touchmove', function(e) {
			var w = $(window).width();
			var currentX = e.originalEvent.touches[0].clientX;
			if(currentX > w) {
				currentX = w;
			} else if (currentX <= 0) {
				currentX = 0;
			}
			$(".modal-image-slider .box-image-differ-ui").width(currentX);
		});

		this.resize();
	}

	// Ajusta os tamanhos de acordo com a orientação da tela
	resize() {
		if($("#uber_blackout .modal-image-slider").length) {
			var
				w = $(window).width(),
				h = $(window).height();
			$(".box-image-differ-ui .box img").css("width", w);
			$( "#uber_blackout .box-image-differ" ).width( w ).height( h );

			var imageSliderHeight = $(".modal-image-slider.box-image-differ img").height();
			$("#uber_blackout .ui-resizable-handle").height('100%');
			$("#uber_blackout .box-image-differ-ui").height(imageSliderHeight).width("50%");
		}
	}

	// Fecha o modal
	hide(){
		$('#uber_blackout').hide();
		$('#main').show();
	}

	registry_events() {
		this.build_images();
		$(window).resize($.proxy(this.resize, this));
		$('#view-view').on('click', '.antes-depois', $.proxy( this.open, this ));
	}

}