/**
 * Modal para Filtros na P�gina (Utilizando o uber_blackout)
 *
 * @author	Caio Nunes - (caio.nunes) <caio.nunes@grupofolha.com.br>
 * @since	2016
 */
export class ModalFilter {
	/**
	 * Abre o modal
	 */
	open(event){
		event.preventDefault();
		console.log(this.constructor.name, this.open.name);

		var modal_offset = $(event.target).offset().top - $(window).scrollTop() ;
		$('#uber_blackout').on('click', (click) => {
			if( click.currentTarget == click.toElement || $(click.toElement).parents('.close_modal').length ){
				$( event.target ).append( $("#modal_property").removeAttr('style').hide() );
				this.hide()
			}

		} ).html( $("#modal_property").show() ).show();

		this.set_offset( modal_offset );
		
	}

	hide(){
		$( '#uber_blackout' ).hide() ;
	}

	set_offset( position ){
		var modal = $( '#uber_blackout #modal_property' ),
			modalheight = modal.outerHeight(),
			windowheight = $( window ).height();

		if( ( position + modalheight ) > windowheight ){
			position = position - ( position - ( windowheight - modalheight ) );
		}
		
		modal.css( 'margin-top', position );
	}

	registry_events(){
		let base = this ;
		$('div#modal_filter').on( 'click' , function( e ) {
			base.open( e ) ;
		} ) ;
	}
}
