/**
 * Limita o tamanho dos texto nas notícias
 *
 * @author  Andrews Guedes - (andrewsfg) <andrews.guedes@grupofolha.com.br>
 * @since   2016
 */

export class FixNewslist {
 
	constructor() {
		this.boot();
	}

	boot() {
		/**
		 * Tamanho do texto em caracteres a ser limitado
		 */
		var size_text = 100 ;

		/**
		 * Encontra o primeiro parágrafo descritivo de um texto (matéria)
		 */

		$( ".latest-news-list-item-content" ).each( function () {
			var $p = $( this ).find( "p" ) ;
			var txt = $p.text() ;
			var href = $( this ).attr( "href" ) ;

		/**
		 * Limita o texto e insere reticências
		 */
			if ( txt.length > size_text ) {
				txt = txt.substring( 0, size_text ) ;
				txt = txt.substring( 0, txt.lastIndexOf( " " ) ).concat( "..." ) ;
				$p.html( txt ) ;
			}
		} ) ;
	}

}