// Importando
import {DateBr} from "./date";
import trim from "./string";
import {Navigation} from "./navigation";
import {Gallery} from "./gallery";
import {Storage} from "./storage";
import {History} from "./history";
import {Menu} from "./menu";
import {ModernizrExt} from "./modernizr";
import {ModalArt} from "./modal_art";
import {ModalImage} from "./modal_image";
import {ModalBeforeAfter} from "./modal_before_after";
import {UOL} from "./uol";
import {URL} from "./url";
import {Rotate} from "./rotate";
import {Share} from "./share";
import {TextResize} from "./text_resize";
import {Video} from "./video";
import {Conversion} from "./conversion";
import {Instagram} from "./instagram";
import {Twitter} from "./twitter";
import {Spotify} from "./spotify";
import {Soundcloud} from "./soundcloud";
import {Deezer} from "./deezer";
import {Audio} from "./audio";
import {Facebook} from "./facebook";
import {ReadMore} from "./read_more";
import {DocumentCloud} from "./documentcloud";
import {Newsletter} from "./newsletter";
import {Faq} from "./faq";
import {Ad} from "./ad";
import {ModalFilter} from "./modal_filter";
import {MedalsOlympic} from "./medals_olympic";
import {Impeachment} from "./impeachment";
import {ImpeachmentSenate} from "./impeachmentsenate";
import {ImpeachmentSenateFinal} from "./impeachmentsenatefinal";
import {FixNewslist} from "./fixnewslist";
import {ChampionshipRound} from "./championshipround";
import {ChampionshipRoundPaulista} from "./championshiproundpaulista";
import {ChampionshipTable} from "./championshiptable";
import {Eleicoes} from "./eleicoes";
import {Urgent} from "./urgent";


// Prototipando
String.prototype.phptrim = trim;

// IoC (Inversion of Control) ~ DI (Dependency injection)
var date = new DateBr();
var storage = Storage.proxy(new Storage());
var history = new History(storage);
var navigation = new Navigation();
var modernizr = new ModernizrExt(navigation);
var modalArt = new ModalArt();
var modalImage = new ModalImage();
var modalBeforeAfter = new ModalBeforeAfter();
var uol = new UOL();
var rotate = new Rotate();
var share = new Share(navigation, modernizr);
var textResize = new TextResize(storage);
var conversion = new Conversion();
var video = new Video();
var url = new URL(navigation);
var menu = new Menu(history, url);
var instagram = new Instagram();
var twitter = new Twitter();
var spotify = new Spotify();
var soundcloud = new Soundcloud();
var deezer = new Deezer();
var audio = new Audio();
var facebook = new Facebook();
var readMore = new ReadMore();
var documentCloud = new DocumentCloud();
var newsletter = new Newsletter();
var faq = new Faq();
var ad = new Ad();
var modalFilter = new ModalFilter();
var medalsOlympic = new MedalsOlympic();
var impeachment = new Impeachment();
var impeachmentSenate = new ImpeachmentSenate();
var impeachmentSenateFinal = new ImpeachmentSenateFinal();
var fixNewslist = new FixNewslist();
var championshipround = new ChampionshipRound;
var championshiproundpaulista = new ChampionshipRoundPaulista;
var championshiptable = new ChampionshipTable;
var eleicoes = new Eleicoes;
var urgent = new Urgent;

// Registra como plugin do JQuery
Gallery.registry();
Gallery.render();

// Eventos
uol.registry_events();
navigation.registry_events();
menu.registry_events();
modalArt.registry_events();
modalImage.registry_events();
modalBeforeAfter.registry_events();
modalFilter.registry_events();
medalsOlympic.registry_events();
rotate.registry_events();
textResize.registry_events();
video.registry_events();
