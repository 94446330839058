/**
 * Compartilhamento
 *
 * @author  Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since   2015
 */
export class Share {

    /**
     * Construtor
     */
    constructor(navigation, modernizr) {
    	this.navigation = navigation;
    	this.modernizr = modernizr;
        this.data = null;
        this.url = {
			facebook: "https://www.facebook.com/sharer.php?u={uri}&utm_source=facebook&utm_medium=social&utm_campaign=compfb&",
			twitter: "https://twitter.com/intent/tweet?source=webclient&via=folha_com&related=folha&lang=pt-br&{uri}&utm_source=twitter&utm_medium=social&utm_campaign=comptw&",
      google: "https://plusone.google.com/_/+1/confirm?hl=pt&{uri}&utm_source=googleplus&utm_medium=social&utm_campaign=compgp&",
			email: "mailto:?{uri}",
			linkedin: "https://www.linkedin.com/shareArticle?mini=true{uri}&utm_source=linkedin&utm_medium=social&utm_campaign=compli&",
			whatsapp: "whatsapp://send?text={uri}&utm_source=whatsapp&utm_medium=social&utm_campaign=compwa Via Folha de S.Paulo"
		};

		//Verifica se a página é uma noticia antes de gerar a barra de share.
		if( typeof page_info !== 'undefined' && typeof page_info.newstext_id !== 'undefined'){
			this.build().check();
		}
    }

	/**
	 * Constroi o menu
	 */
	build(){
		var target = $('.meta h1'),
		facebook_url = this.facebook(),
		whatsapp_url = this.whatsapp(),
		twitter_url = this.twitter(),
		google_url = this.google(),
		email_url = this.email(),
		linkedin_url = this.linkedin(),
		html = '<div class="share">'+
				'<ul id="share-menu">'+
					"<li><a class=\"facebook\" href=\""+ facebook_url +"\" target=\"_blank\">Compartilhar via Facebook</a></li>"+
					"<li><a id=\"whatsapp_share\" class=\"whatsapp\" href=\""+ whatsapp_url +"\" target=\"_blank\">Compartilhar via Whatsapp</a></li>"+
					"<li><a class=\"twitter\" href=\""+ twitter_url +"\" target=\"_blank\">Compartilhar via Twitter</a></li>"+
					"<li><a class=\"google\" href=\""+ google_url +"\" target=\"_blank\">Compartilhar via GooglePlus</a></li>"+
					"<li><a class=\"email\" href=\""+ email_url +"\">Compartilhar via Email</a></li>"+
					"<li><a class=\"linkedin\" href=\""+ linkedin_url +"\" target=\"_blank\">Compartilhar Linkedin</a></li>"+
				'</ul>'+
				'</div>';
		$(html).insertAfter(target);
		return this;
		
	}

	/**
	 * Verifica a plataforma para customizar
	 */
	check(){
		// Garante que o botão do whatsapp não aparece em caso de desktop
		// O e-mail no formato que é mostrado não funciona em desktop
		if ( ! this.modernizr.mobile_detect.os() ) {
			$( "#whatsapp_share" ).parent().hide();
			$("#share-menu .email").parent().hide();
		} else {
			$( "#whatsapp_share" ).show();
			$("#share-menu .email").show();
		}
	}

	/**
	 * *DEPRICATED*
	 * Quando o usuário clicar em compartilhar
	 */
	click(event){
		event.preventDefault();

		// O nome do método leva o mesmo nome da classe, ex.: facebook, twitter
		var method = $(event.currentTarget).attr("class");

		// chama o método de forma dinâmica
		this[method]();
	}

	/**
	 * Retorna a URL que o usuário será enviado ao clicar
	 *
	 * @return {String} URL
	 */
	get_url(){
		let url = page_info.newstext_id || location.href;

		if ( url.startsWith("http") ) {
			return encodeURIComponent( url );
		}
		
		return encodeURIComponent( "https://folha.com/no" + url );
	}

	/**
	 * Retorna o título do conteúdo
	 *
	 * @return {String} Retorna o título
	 */
	get_title(){
		var title = "";

		// Verifica se é uma notícia
		if( this.navigation.is_news() ){
			title = $("header.meta h1:first").text();
		}

		// Se for colunistas adiciona o nome do colunista no título
		var kicker = $("header.meta h5:first").text();
		if( this.navigation.get_current_section() === "colunas" && kicker.length > 0 ){
			title = kicker + " - " + title;
		}

		// Remove qualquer tag HTML e qualquer quebra de texto
		return encodeURIComponent( $.trim( $( "<p>" + title  + "</p>" ).text() ) );
	}

	/**
	 * Retorna o primeiro parágrafo descrição do conteúdo
	 *
	 * @return {String} Retorna o primeiro parágrafo descrição
	 */
	get_pDesription(){
		var pDesription = "";

		// Verifica se é uma notícia
		if( this.navigation.is_news() ){
			pDesription = $("article p:first").text();
		}
		return encodeURIComponent( pDesription );
	}

	/**
	 * Faz o tratamento específico para o Facebook
	 */
	facebook(){
		var url = this.url.facebook.replace( "{uri}", this.get_url() );
		return url;
	}

	/**
	 * Faz o tratamento específico para o Twitter
	 */
	twitter(){
		var url = this.get_url();
		var title = this.get_title();
		var uri = "url=" + url + "&status=" + title + "+" + url + "&content=" + title;

		return this.url.twitter.replace( "{uri}", uri );
	}

	/**
	 * Faz o tratamento específico para o Linkedin
	 */
	linkedin(){
		var url = this.get_url();
		var title = this.get_title();
		var uri = "&url=" + url + "&title=" + title + "&source=Folha%20de%20S.Paulo";

		return this.url.linkedin.replace( "{uri}", uri );
	}

	/**
	 * Faz o tratamento específico para o Google
	 */
	google() {
		var uri = "content=" + this.get_title() + "&url=" + this.get_url();
		return this.url.google.replace( "{uri}", uri );
	}

	/**
	 * Faz o tratamento específico para o e-mail
	 */
	email() {
		var uri = "subject=" + this.get_title() + "&body=Foi compartilhado com você um conteúdo da Folha: %0D%0A %0D%0A" + this.get_title() + "%0D%0A %0D%0A" + this.get_pDesription() + "%0D%0A %0D%0A" + encodeURIComponent(location.href);
		var mail = this.url.email.replace( "{uri}", uri );
		

		/*if ( this.modernizr.mobile_detect.is('iOS') ) {
			window.location.href = mail;
			return;
		}*/

		return mail;
	}

	/**
	 * Faz o tratamento específico para o whatsapp
	 */
	whatsapp() {
		var uri = this.get_title() + " " + this.get_url();
		return this.url.whatsapp.replace( "{uri}", uri );
    }
}
