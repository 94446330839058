/**
 * Championship Table
 *
 * @author  Caio Nunes - <caio.nunes@grupofolha.com.br>
 * @since   2016
 */

export class ChampionshipTable {
    constructor() {
    	this.championshiptable();
    }
    championshiptable(){
    	// Retorna um número aleatório de "n" dígitos
        function random( n ) {
            var min , max ;
            n = n || 10 ;
            min = Math.pow( 10, n - 1 ) ;
            max = Math.pow( 10, n ) - 1 ;
        
            return Math.round( Math.random() * ( max - min ) + min ) ;
        } ;
    	// Rodada Campeonato - Plugin

		$.fn.championship_table = function() {
		
		    function ord(pos) {
		        if (parseInt(pos, 10) === 0) {
		            return pos;
		        } else if (parseInt(pos, 10) < 5) {
		            return '<span class="promoted">' + pos + '</span>';
		        } else if (parseInt(pos, 10) > 16) {
		            return '<span class="demoted">' + pos + '</span>';
		        }
		        return pos;
		    };
		    // date: dd/mm/yyyy
		    function custom_date(date, wday) {
		        var dt = date.split("/");
		        return week_days[parseInt(wday, 10) - 1] + " - " + dt[0] + "." + months[parseInt(dt[1], 10) - 1];
		    };
		
		    function to_slug(name) {
		        return name.toLowerCase()
		            .replace(/\u00E1|\u00E2|\u00E3/g, 'a')
		            .replace(/\u00E9|\u00EA|\u00EB/g, 'e')
		            .replace(/\u00ED/g, 'i')
		            .replace(/\u00F3|\u00F4|\u00F5/g, 'o')
		            .replace(/\u00FA|\u00FC/g, 'u')
		            .replace(/\u00E7/g, 'c')
		            .replace(/[\s\-]+/g, '_')
		            .replace(/\W/g, '');
		    };
		
		    function ChampionshipController(ext) {
		        for (var opt in ext) {
		            this[opt] = ext[opt];
		        }
		    };
		
		    var championshipid = this[0].id,
		        championship = this.id = {}
		
		    ChampionshipController.prototype.config = null;
		    ChampionshipController.prototype.container = function() {
		        return $(this.config.selector);
		    };
		    ChampionshipController.prototype.test = function() {
		        return this.container().length === 1;
		    };
		    ChampionshipController.prototype.get_src = function() {
		        return $.trim(this.container().data('src'));
		    };
		    ChampionshipController.prototype.get_name = function() {
		        var arrName = this.container().data('src').split('/');
		        for ( var i = 0; i < Object.keys(arrName).length; i++) {
		            if (arrName[i] === "virtual") {
		                var name_champ = arrName[i - 1];
		                name_champ = name_champ.replace(/serie/g, 'série');
		                name_champ = name_champ.replace(/-|_/g, ' ');
		            }
		        }
		        return name_champ
		    };
		    ChampionshipController.prototype.render = function(data) {
		        // filter
		        data = this.filter(data);
		
		        this.get_name();
		
		        this.container().html(this.mount(data));
		    };
		    ChampionshipController.prototype.mount = function(data) {
		        var name_champ = this.get_name();
		        var teamsObj = data.groups[0].teams;
		
		        var html = '';
		        html += '<h3>' + name_champ + '</h3>';
		        html += '<table class="group">';
		        html += '	<thead>';
		        html += '	<tr>';
		        html += '		<th class="text-col bold" colspan="2"></th> <th>P</th> <th>J</th> <th>V</th> <th>SG</th>'
		        html += '	</tr>';
		        html += '	</thead>';
		        html += '	<tbody>';
		        for ( var i = 0; i < teamsObj.length; i++) {
		            html += '<tr>';
		            html += '<td class="bold">' + ord(teamsObj[i].pos) + '</td>';
		            html += '<td class="text-col bold"><span class="flag"><img width="25" height="25" src="//f.i.uol.com.br/folha/esporte/futebol/escudo/2015/' + to_slug(teamsObj[i].name) + '-100x100.png" alt="' + teamsObj[i].name + '(Bandeira)"></span>' + teamsObj[i].id + '</td>';
		            html += '<td class="number-col bold">' + teamsObj[i].pts + '</td>';
		            html += '<td>' + teamsObj[i].pld + '</td>';
		            html += '<td>' + teamsObj[i].w + '</td>';
		            html += '<td>' + teamsObj[i].gd + '</td>';
		            html += '</tr>';
		        }
		        html += '	</tbody>';
		        html += '</table>';
		
		        return html
		    }
		    ChampionshipController.prototype.filter = function(data) {
		        return data;
		    };
		    ChampionshipController.prototype.set_type = function() {
		        var type = $.trim(this.container().data('type'));
		        if (type == "auto-refresh") {
		            this.requestInterval(this);
		        }
		    };
		    ChampionshipController.prototype.set_qty = function() {
		        return $.trim(this.container().data('qty'));
		    };
		    ChampionshipController.prototype.requestInterval = function(object) {
		        function controllerRequest() {
		            object.request();
		        }
		        setInterval(controllerRequest, 22000);
		    }
		    ChampionshipController.prototype.request = function() {
		        var bust = Math.floor((new Date).getTime() / 10000) ;
		        $.ajax({
           			url: "https://m.folha.uol.com.br" + this.get_src() + '?_' + bust,
            		cache: true , 
            		dataType: "jsonp" ,
		            jsonpCallback: this.config.callback,
		            success: $.proxy(this.render, this),
            		error: function(xhr, textStatus, errorThrown ) {
                    	console.log(xhr + " , " + textStatus + " , " + errorThrown);
                	}
		        });
		    };
		    ChampionshipController.prototype.init = function() {
		        if (!this.config.selector) {
		            throw new Error('[ChampionshipController] Container selector not defined.');
		        }
		        if (!this.config.callback) {
		            throw new Error('[ChampionshipController] jsonp callback not defined.');
		        }
		        this.test();
		        this.request();
		        this.set_type();
		    };
		
		    championship.groups = new ChampionshipController({
		        config: {
		            selector: '.championship-table#' + championshipid + '[data-src]',
		            callback: 'tabela'
		        },
		        filter: function(data) {
		            var limitRound = this.set_qty();
		            if (limitRound != '' && !isNaN(limitRound)) {
		                var toSlice = data[0].teams.length - limitRound;
		                data[0].teams.splice(limitRound, toSlice);
		            }
		
		            return {
		                groups: data
		            };
		        }
		    });
		
		    championship.groups.init();
		
		}

	 	$( '.championship-table ' ).each(function(){
        	$( this ).championship_table();
    	})

    }
}