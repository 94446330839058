/**
 * String
 *
 * @see  	https://gist.github.com/lsauer/1260006
 * @author  Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since   2015
 */
export default function(s){
	if(0 === arguments.length) return this.valueOf().trim();

	var restr = '['+s.split('').join('|')+']*';
	var re = RegExp('^'+restr+'|'+restr+'$','g');
	return this.valueOf().replace(re,'');
}