/**
 * Menu
 *
 * @author	Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since	2015
 */
export class Menu {

	/**
	 * Construtor
	 */
	constructor(history, url) {
		this.url = url;
		this.history = history;
	    this.slide_time = 100;
	    this.timing_nav = false;
	    this.login_url = "https://%slogin.folha.com.br/login?done=";
	    this.set_user_links();
	    this.csscalc();
	}

	/**
	 * Envia o usuário para a versão clássica
	 */
	click_classic(event){
		event.preventDefault();
		location.href = this.url.get_classic_desktop();
	}

	/**
	 * Configura links de Login/Logout apos retorno do paywall
	 */
	set_user_links(){
		var url_paywall = "https://paywall.folha.uol.com.br" ;

		$( "#extramenu .logged_not_only" ).hide();
		$( "#extramenu .logged_only" ).hide();

		if ( location.hostname.search( "folha.uol" ) < 0 ) {
			url_paywall = url_paywall.replace( /uol./ , "" ) ;
		}

		$.ajax( {
			url: url_paywall + "/esta-logado.jsonp",
			cache: true,
			dataType: "jsonp",
			success: function( data ) {
				var url_logout = url_paywall + "/logout?done=",
				url_login = url_paywall + "/folha/login?return_url=",
				url_edit = "https://login.folha.com.br/edit?service=paywall/frontend&done=",
				url_register = url_paywall + "/folha/login?return_url=",
				location_href = encodeURIComponent( location.href ) ;

				//Ações logado
				if ( data.logged === "yes" ) {
					url_logout += location_href ;
					url_edit += location_href ;
					$( "#click_logout_user" ).attr( "href" , url_logout ) ;
					$( "#click_edit_user" ).attr( "href" , url_edit ) ;
					$( "#extramenu .logged_only" ).show() ;
				}
				else {
					url_login += location_href + "&logintype=navfolha&urltype=login" ;
					url_register += location_href + "&urltype=register";
					$( "#click_login_user" ).attr( "href" , url_login ) ;
					$( "#click_register_user" ).attr( "href" , url_register ) ;
					$( "#extramenu .logged_not_only" ).show() ;
				}

				//Ações assinante
				if ( data.subscriber === "yes" ) {
					$( "#extramenu .logged_subscriber" ).hide() ;
				}
			}
		} ) ;
	}

	/**
	 * Retorna a URL de login do usuário
	 *
	 * Não adicionar o hash, o back do login já verifica
	 *
	 * @return {String} URL
	 */
	get_url_login(){
		var url_destination = encodeURIComponent( location.href );
		var service = "service=" + encodeURIComponent("html5v2/frontend");

		if ( location.hostname.split(".",1)[0] == "staging" ) {
			this.login_url = this.login_url.replace(/%s/, "dev.");
			this.login_url = this.login_url.replace(/https/, "http");
		}

		this.login_url = this.login_url.replace(/%s/g, "");

		return this.login_url + url_destination + "&" + service;
	}

	/**
	 * Registra o click de voltar
	 */
	button_go_back( event ){
		event.preventDefault();
		this.history.back();
	}

	/**
	 * Fecha todos os menus
	 */
	hide_all_menu(){
		this.hide_menu();
		this.hide_menu_right();
	}

	/**
	 * Abre e fecha o menu direito
	 */
	toggle_menu_right(event){
		event.preventDefault();
		var hide = $("#extramenu").is( ":visible" );
		this.hide_all_menu();

		if ( ! hide ) {
			if( Modernizr.no_menu ) {
				$("#extramenu").toggle();
			}else {
				$("#extramenu").fadeToggle( {
					duration: this.slide_time
				} );
			}
		}

		$(window).one('scroll', $.proxy( this.hide_menu_right, this ) );
	}

	/**
	 * Fecha o menu direito
	 */
	hide_menu_right(){
		if ( $("#extramenu").is(":visible") ) {
			if( Modernizr.no_menu ) {
				$("#extramenu").hide();
			}else {
				$("#extramenu").fadeOut( this.slide_time );
			}
		}
	}

	/**
	 * Abre e fecha o menu
	 */
	toggle_menu() {
		console.log(this.constructor.name, this.toggle_menu.name);

		if ( $("#blackout").is(":visible") ) {
			this.hide_menu();
		} else {
			this.hide_all_menu();
			this.show_menu();
		}
	 }

	 /**
	  * Remove/mostra o menu de navegação
	  */
	 show_menu() {
		if ( this.timing_nav ) return;
		console.log(this.constructor.name, this.show_menu.name);
		this.timing_nav = true;

		if( Modernizr.no_menu || Modernizr.partial_menu ) {
			$("#blackout, #nav-local").show();
			this.show_menu_complete();
			return;
		}

		$("#blackout").velocity(
			{
				opacity: 1
			},
			{
				display: "block",
				duration: "fast",
				complete: $.proxy( this.after_move_blackout, this )
			}
		);
	 }

	 /**
	  * Após mostrar o menu lateral
	  */
	 after_move_blackout() {
	 	$("#nav-local").velocity(
	 		{ left: "+=300" },
	 		{ duration: "fast", complete: $.proxy( this.show_menu_complete, this ) }
 		);
	 }

	 /**
	  * Após mostrar o menu
	  */
	 show_menu_complete() {
	 	//Adiciona class na container
		this.timing_nav = false;
		this.update_blackout_height();
	 }

	 /**
	  * Remove o menu de navegação
	  */
	 hide_menu() {
	 	// Caso já não esteja aberto o menu
		if ( this.timing_nav || ! $("#blackout").is(":visible") ) return;
		console.log(this.constructor.name, this.hide_menu.name);
		this.timing_nav = true;

		if( Modernizr.no_menu || Modernizr.partial_menu ) {
			$("#blackout, #nav-local").hide();
			this.hide_blackout_complete();
			return;
		}

		$("#blackout").velocity(
			{
				opacity: 0
			},
			{
				display: "none",
				duration: "fast",
				complete: () => {
					$("#nav-local").velocity(
				 		{ left: "-=300" },
				 		{
				 			duration: "fast",
				 			complete: $.proxy( this.hide_blackout_complete, this )
				 		}
			 		);
				}
			}
		);
	 }

	 /**
	  * Após concluir de remover o menu
	  */
	 hide_blackout_complete(){
		this.timing_nav = false;
	 }

	/**
	  * Atualiza a altura do blackout
	  */
	 update_blackout_height(){
	 	var h = $("#nav-local ul").height();
		var h2 = $("#main").height();

		if ( h > h2 ) {
			h2 = h;
		}

		if ( $("#blackout").height() != h2 ) {
			console.log(this.constructor.name, this.update_blackout_height.name, h2);
			$("#blackout").height( h2 );
		}
	 }

	 /**
	  * Quando o usuário clicar para realizar o login
	  */
	 click_login(event){
	 	event.preventDefault();
		location.href = this.get_url_login();
	 }

	 /**
	  * Verifica o suporte a calc do css
	  * https://caniuse.com/#search=calc
	  */
	 csscalc(){
	 	console.log(this.constructor.name, this.csscalc.name);

	 	if( Modernizr.no_menu || Modernizr.partial_menu ) {
	 		var height = $("#nav-local ul").height();

			$("#nav-local")
				.css( "overflow", "hidden")
				.css( "position", "absolute")
				.css( "display", "none")
				.css( "left", "0px")
				.height( height );

			$("#bar").css( "position", "absolute");
			$("#blackout").css( "opacity", "1" );
		}

		this.update_blackout_height();
	 }

	 /**
	  * Eventos
	  */
	 registry_events(){
	 	$(window).on('folha_touch_move folha_help_show folha_shared', $.proxy( this.hide_all_menu, this ) );
		$("#goback").on("click", $.proxy( this.button_go_back, this ));
		$('#blackout, #view-view').on( 'click', $.proxy( this.hide_all_menu, this ) );
		$('.toggle-nav').on('click', $.proxy( this.toggle_menu, this ) );
		$('.extra-toggle').on('click', $.proxy( this.toggle_menu_right, this ));
		$('#extramenu .click_login').on('click', $.proxy( this.click_login, this ));
		$('#extramenu #classic a').on('click', $.proxy(this.click_classic, this));
	 }
}