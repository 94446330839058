/**
 * Rotate
 *
 * @author  Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since   2015
 */
export class Rotate {

    /**
	 * Altera a imagem do rotate
	 */
	change(event) {
		event.preventDefault();
		var current_clicked = $(event.currentTarget);
		// a -> controls -> content -> current
		var current_img = current_clicked.parent().parent().parent();
		// posição atual
		var current_position = parseInt(current_img.attr('data-order'));
		// última posição
		var last_position = parseInt(current_img.parent().find('ul li:last').attr('data-order'));

		console.log('Rotate current position', current_position);
		console.log('Rotate last position', last_position);

		// Altera a posição do rotate
		if(current_clicked.hasClass('button-prev')){
			current_position--;

			// Vai para o último item do rotate
			if(current_position < 0){
				current_position = last_position;
			}
		} else {
			current_position++;

			// Caso tenha chegado no final envia para o início
			if(current_position > last_position) {
				current_position = 0;
			}
		}

		console.log('Rotate new position', current_position);
		// rotate
		current_img.html(
			current_img
				.parent()
				.find(
					String("ul li[data-order='%s']").replace('%s', current_position)
				).html()
		).attr('data-order', current_position);
	}

	/**
	 * Registra os eventos
	 */
	registry_events(){
		$(document).on('click', '#view-view .rotate .current .controls a', $.proxy( this.change, this));
	}
}