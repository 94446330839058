/**
 * Medalhas da Rio2016
 *
 * @author	Caio Nunes - (caio.nn) <caio.nunes@grupofolha.com.br>
 * @since	2016
 */
export class MedalsOlympic {

	constructor() {
		//
	}

  lowercase( value ){
    return value.toLowerCase();
  }

	mount_widget( data ){
		let core = this,
        html = '',
	  		brasil = '',
  			limitTo = 3;
  		$.each(data.country, function( i, x ) {
  			if( data.country[i].code == 'BRA' && data.country[i].pos > 3 ){
	  			brasil += '<tr class="active">';
  				brasil += '	<td><span class="kicker">' + data.country[i].pos + 'º</span></td>';
  				brasil += '	<td><img src="//f.i.uol.com.br/folha/especial/2016/olimpiada-no-rio/country/'+ core.lowercase(data.country[i].code) +'.png" alt="Medalhas '+ data.country[i].name +'" title="Medalhas '+ data.country[i].name +'" class="country-icon">'
  					 	+ data.country[i].code + '</td>';
  				brasil += '	<td>' + data.country[i].gold + '</td>';
  				brasil += '	<td>' + data.country[i].silver + '</td>';
  				brasil += '	<td>' + data.country[i].bronze + '</td>';
  				brasil += '	<td>' + data.country[i].total + '</td>';
  				brasil += '</tr>';
  			return false
  			}
  		})
  		$.each(data.country, function( i, x ) {
  			if( data.country[i].code == 'BRA' && data.country[i].pos > 3 ){
  				
  			}else{
  				html += '<tr>';
  				html += '	<td><span class="kicker">' + data.country[i].pos + 'º</span></td>';
  				html += '	<td><img src="//f.i.uol.com.br/folha/especial/2016/olimpiada-no-rio/country/'+ core.lowercase(data.country[i].code) +'.png" alt="Medalhas '+ data.country[i].name +'" title="Medalhas '+ data.country[i].name +'" class="country-icon">'
  							 + data.country[i].code + '</td>';
  				html += '	<td>' + data.country[i].gold + '</td>';
  				html += '	<td>' + data.country[i].silver + '</td>';
  				html += '	<td>' + data.country[i].bronze + '</td>';
  				html += '	<td>' + data.country[i].total + '</td>';
  				html += '</tr>';
  				if( (i + 1) == limitTo )
  					return false
  			}
  		});

  		html += brasil;

  		$("#medals-olympic tbody").html( html );
	}

  ranking_data( data ){
    let tmp;
    data.country.map( function( a, indexA ){
      data.country.map( function( b, indexB ){
          if( parseInt( a.gold ) > parseInt( b.gold ) ){ // Compara ouro
            tmp = data.country[indexA];
            data.country[indexA] = data.country[indexB];
            data.country[indexB] = tmp;
          }else if( parseInt( a.gold ) == parseInt( b.gold ) ){
            if( a.silver > b.silver ){ // Compara prata
              tmp = data.country[indexA];
              data.country[indexA] = data.country[indexB];
              data.country[indexB] = tmp;
            }else if( parseInt( a.silver ) == parseInt( b.silver ) ){
              if( a.bronze > b.bronze ){ // Compara bronze
                tmp = data.country[indexA];
                data.country[indexA] = data.country[indexB];
                data.country[indexB] = tmp;
              }// bronze 
            }
          }// ouro
      })
    })

    return data
  }

  mount_paralympic_widget( data ){
    this.ranking_data( data );
    let core = this,
        html = '',
        brasil = '',
        append_brasil = false,
        show_pos,
        limitTo = 3;
      $.each(data.country, function( i, x ) {
          if( data.country[i].code == 'BRA' && ( i+1 ) > 3 ){
            if( data.country[i].gold == 0 && data.country[i].silver == 0 && data.country[i].bronze == 0 ){
              data.country[0].gold == 0 && data.country[0].silver == 0 && data.country[0].bronze == 0 ? show_pos = '1º' : show_pos = '-'
            }
            else{
              show_pos = ( i + 1 ) + 'º'
            }
            brasil += '<tr class="active">';
            brasil += ' <td><span class="kicker">' + show_pos + '</span></td>';
            brasil += ' <td><img src="//f.i.uol.com.br/folha/especial/2016/olimpiada-no-rio/country/'+ core.lowercase(data.country[i].code) +'.png" alt="Medalhas '+ data.country[i].name +'" title="Medalhas '+ data.country[i].name +'" class="country-icon">'
                 + data.country[i].code + '</td>';
            brasil += ' <td>' + data.country[i].gold + '</td>';
            brasil += ' <td>' + data.country[i].silver + '</td>';
            brasil += ' <td>' + data.country[i].bronze + '</td>';
            brasil += ' <td>' + data.country[i].total + '</td>';
            brasil += '</tr>';
            return false
          }
        })
        $.each(data.country, function( i, x ) {
          if( i > 0 ){
            data.country[i].gold == data.country[i-1].gold && data.country[i].silver == data.country[i-1].silver && data.country[i].bronze == data.country[i-1].bronze ? data.country[i].pos = data.country[i-1].pos : data.country[i].pos = ( i + 1 );
            ( data.country[i].pos - data.country[i-1].pos ) > 1 ? data.country[i].pos-- : data.country[i].pos = data.country[i].pos;
          }
          if( data.country[i].code == 'BRA' && ( i+1 ) > 3 ){
            
          }else{
            html += '<tr>';
            html += ' <td><span class="kicker">' + data.country[i].pos + 'º</span></td>';
            html += ' <td><img src="//f.i.uol.com.br/folha/especial/2016/olimpiada-no-rio/country/'+ core.lowercase(data.country[i].code) +'.png" alt="Medalhas '+ data.country[i].name +'" title="Medalhas '+ data.country[i].name +'" class="country-icon">'
                   + data.country[i].code + '</td>';
            html += ' <td>' + data.country[i].gold + '</td>';
            html += ' <td>' + data.country[i].silver + '</td>';
            html += ' <td>' + data.country[i].bronze + '</td>';
            html += ' <td>' + data.country[i].total + '</td>';
            html += '</tr>';
            if( (i + 1) == limitTo )
              return false
          }
        });
      html += brasil;
      $("#medals-paraolympic tbody").html( html );
  }

	request_data( ) {
		let core = this, path;
		if (window.location.href.indexOf("staging.mobile") != -1) {
      		path = 'staging.www1.folha.com.br';
    	} else {
      		path = 'www1.folha.uol.com.br';
    	}

    $.ajax({
      method: "GET",
      url: "//" + path + "/esporte/olimpiada-no-rio/medalhas/virtual/1/common/medalhas-total-ranking.json",
      jsonpCallback: "total_medals",
      cache: false,
      dataType: "jsonp",
      success: function( data ){
      	core.mount_widget( data[0] );
      }, error: function( err ){
        console.log( "Error ajax on MedalsOlympic" ) ;
        console.log( err ) ;
      }
    });
	}

  request_paralympic_data( ) {
    let core = this, path;
    if (window.location.href.indexOf("staging.mobile") != -1) {
          path = 'staging.www1.folha.com.br';
      } else {
          path = 'www1.folha.uol.com.br';
      }

    $.ajax({
      method: "GET",
      url: "//" + path + "/esporte/olimpiada-no-rio/medalhas/virtual/1/common/medalhas-paralimpicas.json",
      jsonpCallback: "total_paralimpic_medals",
      cache: false,
      dataType: "jsonp",
      success: function( data ){
        core.mount_paralympic_widget( data[0] );
      }, error: function( err ){
        console.log( "Error ajax on MedalsOlympic" ) ;
        console.log( err ) ;
      }
    });
  }

	/**
	 * Registra os eventos
	 */
	registry_events() {
    if( $("#medals-olympic tbody").length >= 1 ){
      this.request_data();
    }
    if( $("#medals-paraolympic tbody").length >= 1 ){
      this.request_paralympic_data();
    }
		
	}
}