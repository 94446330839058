import {ModalArt} from "./modal_art";

/**
 * Abre o modal das imagens
 *
 * @author  Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since   2015
 */
export class ModalImage extends ModalArt {
	/**
	 * Abre o modal
	 */
	open(event){
		event.preventDefault();
		console.log(this.constructor.name, this.open.name);

		let base = this ;

		$( "#uber_blackout" ).one( "click" , function () {
			console.log( "aki ", base.position_top )
			super.hide( base.position_top )

		} ).html( $( "#modal_base" ).html() )
			.show()
			.find(".image").html(
				$('<img>').attr( 'src', $(event.currentTarget).find("img").attr('src') )
			)
			.parent()
			.addClass('modal');

		$( "#main" ).hide();
	}

	/**
	 * Eventos
	 */
	registry_events(){
		let base = this ;

		$('#view-view .articleGraphic').on( 'click' , function( e ) {
			let el = $( this ) ;

			if (el.find('a').length) {
				return;
			}

			base.position_top = el.position().top ;
			base.open( e ) ;
		} ) ;
	}
}
