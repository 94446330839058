/**
 * Simples interface para o localStorage
 *
 * @author	Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since	2015
 */
export class Storage {
	/**
	 * Construtor
	 */
	constructor() {
		this.data = {};
	}

	/**
	 * Seta um item
	 */
	setItem(item, value){
		this.data[item] = value;
	}

	/**
	 * Recupera um item
	 */
	getItem(item){
		var value = this.data[item];

		if ( value === undefined ) {
			return null;
		}

		return value;
	}

	/**
	 * Remove um item
	 */
	removeItem(item){
		delete this.data[item];
	}

	/**
	 * Adiciona o proxy
	 */
	static proxy(storage){
		try {
			var key = new Date().getTime().toString();
			localStorage.setItem( key , key );

			if ( localStorage.getItem( key ) !== key ) {
				throw "fail";
			}

			localStorage.removeItem( key );
			storage = localStorage;
		}
		catch( e ) {
			console.log( e.stack );
		}

		return storage;
	}
}