/**
 * Galeria de imagens
 *
 * @author	Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since	2015
 */
export class Gallery {
	/**
	 * Construtor
	 */
	constructor(el) {
	    this.options = {
			elem: null ,
			el: $(el).attr('id'),
			$el: $(el),
			gallery: {} ,
			position: 0 ,
			fotografia_url: '',
			re: /^(http(s)?\:)?\/\/.*?fotografia\.folha(\.uol|)\.com\.br\/galerias\/(nova\/)?([0-9]+)\-(.*)/,
			reIdGallery: /galerias\/(nova\/)?([0-9]+)/,
	    idGallery: null,
		};

		this.check_url();
		this.setIdGallery();
		
		//Chamada JSONP para a galeria
		$.ajax({
			url: `${this.options.fotografia_url}.jsonp?callback=?`,
			dataType: 'jsonp',
			jsonpCallback: `callback${this.options.idGallery}`,
			success: $.proxy( function(res) {
				var data = this.toValuesString(res);

				this.options.gallery.image = data.images[ this.get_position( data.images ) ] ;
				this.options.gallery.images_idx = this.options.gallery.image.idx;
				this.mount() ;
				},
				this
			),
			error: (jqXHR, textStatus, errorThrown) => {
				console.log('Error: ', jqXHR, textStatus, errorThrown);
				console.log(`${this.options.fotografia_url}${this.options.gallery.id}.jsonp`);
			}
		});
	}

	/**
	 * Registra como uma função do Jquery
	 */
	static registry(){
		$.fn.gallery = function (){
			new Gallery(this);
		};
	}

	/**
	 * Renderiza a galeria
	 */
	static render(){
		$( "#view-view .articleGallery" ).each(function() {
			$(this).gallery();
		});
	}

	/**
	 * Monta o HTML
	 */
	mount() {
		//DIV crédito
		this.options.elem ="<div class=\"graphic-credit\">"+ this.options.gallery.image.credit + "</div>";

		//URL da galeria Fullsize
		this.options.elem += `<a href="/gallery/${location.search}#galeria=${this.options.gallery.id},foto=${this.options.gallery.images_idx},ref=${location.pathname.phptrim('/')},fotografia_url=${this.options.fotografia_url}">`

		//Primeira imagem
		this.options.elem += "<img src=\"" + this.options.gallery.image.image_gallery + "\"><\/a>";

		//Div legenda
		this.options.elem += "<div class=\"graphic-caption\"><p>" + this.options.gallery.image.legend + "</p></div>";

		//Adiciona a nova div criada depois da original
		$('#'+this.options.el).html( this.options.elem ) ;
	}

	toStaticSrc(src_gallery) {
    var re = /(.*)(fotografia)(\.folha\.[^?#]*)(.*)/;

    return src_gallery.replace(re, '$1galerias$3').replace('/nova/', '/');
  }

  toValuesString(response) {
    var data = $.extend({images: []}, response);

    data.images = $.map(data.images, function(img) {
      var image = $.extend({}, img);

      $.each(image, function(key, value) {
        if (value === null) {
          image[key] = '';
        }

        if ($.isNumeric(value)) {
          image[key] += '';
        }
      });

      return image;
    });

    return data;
  }

	/**
	 * Verifica as URL's
	 */
	check_url() {
		let a = this.options.$el.find('a').get(0);
		let fragments = a.hash.split(',');

		// Gallery url
		this.options.fotografia_url = this.toStaticSrc(a.dataset.url.split('#')[0]);

		//Lê o href do link com os valores da galeria
		this.options.gallery.url = a.href ;

		//Obtém o id da galeria
		this.options.gallery.id = a.hash.split(',').shift().substring(1).split('=')[1];

		//Verifica se a galeria tem uma foto pré selecionada para exibição
		if( fragments[1].indexOf("foto") !== -1 ){
			this.options.gallery.images_idx = fragments[1].split('=')[1];
		}
		else{
			this.options.gallery.images_idx = '';
		}

		return true;
	}

	/**
	 * Recupera a posição
	 */
	get_position( images ) {
		//Se o IDX não estiver vazio, localiza a imagem pelo IDX correspondente dentro do JSONP
		if ( this.options.gallery.images_idx !== "" ) {
			var idx = this.options.gallery.images_idx ;

			for ( var i = 0 , len = images.length ; i < len ; i++ ) {
				if ( idx == images[ i ].idx ) {
					this.options.position = i ;
					break;
				}
			}
		}
		//Caso o IDX venha vazio, pegamos a primeira imagem retornada no JSONP
		else{
			this.options.position = 0 ;
		}

		return this.options.position;
	}

	setIdGallery() {
		const url = this.options.fotografia_url;
		const regex = this.options.reIdGallery;
    const matched = url.match(regex);
    const hasID = (matched && matched.length > 1);

    this.options.idGallery = (hasID) ? matched[2] : '';
  };
}
