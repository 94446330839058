export class Ad {

	constructor() {
		this.element = $('#uber_blackout');
		this.active = folha_intestitial_ad || false;

		if(this.isEnabled()){
			setTimeout(() => { this.getWrapper().getIframe().show() }, 750);
			setTimeout(() => { this.hide() }, 10000);
		}
	}

	/**
	 * Constroi o menu
	 */
	getWrapper() {
		var ad_container = $('<div />', {class: 'ad-wrapper ad-320'}),
			ad_tag 		 = $('<div />', {class: 'ad-tag', text: 'Publicidade'}),
			ad_iframe 	 = this.getIframe(),
			ad_close	= $('<span />',{class:'close-ad', text: 'X'});

		this.element.append(ad_container.append(ad_tag).append(ad_close));

		$('.close-ad').on("click", () => { this.hide(); });

		return this;
	}

	getIframe(xy, id) {
		let elementWrapper = this.element.find('.ad-wrapper.ad-320');

		xy = xy || '320x480';

		elementWrapper
		.append($('<iframe />', {
			src: this.getIframeSource(id), 
			scrolling: 'no',
			frameborder: '0',
			height: '480',
			width: '320',
		}).addClass("banner-" + xy));

		elementWrapper.css({
			'top': (( this.isWindowHeight() - 480 ) / 2).toFixed(0) + 'px',
			'left': '0px'
		});

		return this;
	}

	getIframeSource(id, xy, section_slug, color) {
		var section_slug 	= section_slug || 'galeria',
			banner_type 	= xy || '320x480',
			banner_id 		= id !== undefined ? '&banner_id=' + id : '',
			color 			= color || 'dddddd';

		return 'https://m.folha.uol.com.br/virtual/common/2/ads/'
			+ '?section=' + section_slug
			+ '&banner=' + banner_type
	}

	isEnabled() {
		console.log(this.active);
		return this.active;
	}

	isWindowWidth(){
		return window.innerWidth > 0 ? window.innerWidth : screen.width;
	}

	isWindowHeight(){
		return window.innerHeight > 0 ? window.innerHeight : screen.height;
	}

	show() { 
		$('body').css('position','fixed');
		this.element.show();

		return this;
	}

	hide() {
		$('body').removeAttr('style');
		this.element.hide();

		return this;
	}
}