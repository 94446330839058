/**
 * Histórico de navegação
 *
 * @author	Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since	2015
 */
export class History {
	/**
	 * Construtor
	 */
	constructor(storage) {
		this.storage = storage;
	    this.key = "folha_history";
	    this.size = 50;
	    this.registry();
	}

	/**
	 * Registra a URL atual no histórico
	 *
	 * @return {boolean} Verdadeiro caso o histórico seja registrado
	 */
	registry() {
		let historyNav = JSON.parse( this.storage.getItem( this.key ) );
		let pathname = location.href;

		// Histórico não existe, inicia ele
		if ( historyNav === null ) {
			historyNav = [];
		}

		// Chegou ao número máximo de itens, remove os primeiros
		if ( historyNav.length >= this.size ) {
			historyNav.shift();
		}

		// Armazena caso o item não seja repetido
		if ( historyNav[ historyNav.length - 1 ] != pathname && pathname !== "" ) {
			console.log(this.constructor.name, this.registry.name, pathname);
			historyNav.push( pathname );
			this.storage.setItem( this.key , JSON.stringify( historyNav ) );
			return true;
		}

		return false;
	}

	/**
	 * Volta para a página anterior
	 *
	 * @return {null}
	 */
	back() {
		var historyNav = JSON.parse( this.storage.getItem( this.key ) );

		// Se não tiver histórico ou se existir apenas a página atual
		if ( historyNav === null || historyNav.length < 2 ) {
			return;
		}

		historyNav.pop(); // descarta a página atual
		var last = historyNav.pop(); // recupera a página anterior
		this.storage.setItem( this.key , JSON.stringify( historyNav ) );
		location.href = last;
	}
}