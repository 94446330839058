/**
 * Urgent 2017 - Caixa de mat�ria urgente da Home
 *
 * @author  Wellington Mota - <wellington.mota@grupofolha.com.br>
 * @since   2017
 */

export class Urgent {
	constructor() {
		this.init();
	}
	init() {
		const el = document.querySelector('.urgent');

		if (el) {
			el.querySelector('.close').addEventListener('click', e => {
				e.preventDefault();

				el.classList.add('urgent-hidden');
				setTimeout(() => {
					el.parentNode.removeChild(el);
				}, 600);
			});
		}
	}
}