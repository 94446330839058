/**
 * Faq
 *
 * @author	Laura Leamari - <laura.leamari@grupofolha.com.br>
 * @since	2016
 * 
 */

export class Faq{
	/*
	 * Constructor
	 */

	constructor() {

		this.faq();
	}

	faq(){
		var 
			list = $('.list-square > li > h3'),
			answer = $('.list-square > li > .section_text'),
			arrow = $('.list-square > li > h3 span')

		$('.list-square > li > h3').on( "click" , function(e) {
			var lista = $(this);
			if (lista.attr('class') != 'active'){
			    	$(this).addClass('active');
					answer.slideUp();
					arrow.removeClass('arrowOpen');
					arrow.addClass('arrow');
					answer.css('padding','0px 0px 10px 0');
					lista.next().slideToggle();
					list.removeClass('active');
					lista.addClass('active');
					lista.find('span').removeClass('arrow');
					lista.find('span').addClass('arrowOpen');
				}  else {
					list.removeClass('active');
					answer.slideUp();
					arrow.removeClass('arrowOpen');
					arrow.addClass('arrow');
				}
		});

		var data = $('.fsp .indice.textos .section-masthead .label').text().split('/')
		for( var i = 0; i < data.length; i ++ ){
		    data[i] = parseInt(data[i], 10);
		}
		$('.fsp .indice.textos .section-masthead.date .label').text(data.join('/'));

		return this;
	}

}