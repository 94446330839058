/**
 * Facebook
 *
 * @author	Anderson Felix - (a.felix) <anderson.felix@grupofolha.com.br>
 * @since	2015
 */
export class Facebook {
	/**
	 * Construtor
	 */
	constructor() {
		var base = this ;

		base.$_el = $( "#main article p.facebook" ) ;

		if ( base.$_el.length > 0 ) {
			base.prepare() ;

			base.$_el.each( function() {
				base.mount_embed( $( this ) ) ;
			} ) ;
		}
	}

	prepare() {
		if ( !$( '#fb-root' ).length ) {
			$( 'body' ).append( $( document.createElement( 'div' ) ).attr( 'id' , 'fb-root' ) ) ;
		}

		if ( !$( '#facebook-jssdk' ).length ) {
			(function(d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = "//connect.facebook.net/pt_BR/sdk.js#xfbml=1&version=v2.3"; fjs.parentNode.insertBefore(js, fjs);}(document, 'script', 'facebook-jssdk'));
		}
	}

	/**
	 * Replace p.instagram > a
	 * em iframe
	 */
	mount_embed( $el ) {
		let url = $el.find( "a" ).attr( "href" ) ,
			re  = /^https?\:\/\/(www\.)?facebook\.com\//gi ;

		if ( ! url.match( re ) ) {
			return ;
		}

		$el.after(
			$( "<div \/>" )
				.addClass( "fb-post" )
				.attr( "data-href" , url )
				.css( {
					marginBottom: ".8em" ,
					width: "100%"
				} )
		).remove() ;
	}
}