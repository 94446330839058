/**
 * Deezer
 *
 * @author	Evandro Santos - (ev.santos) <evandro.santos@grupofolha.com.br>
 * @since	2015
 */
export class Deezer {
	/**
	 * Construtor
	 */
	constructor() {
		var base = this ;

		$( "p.deezer" ).each( function() {
			base.mount( $( this ) ) ;
		} ) ;
	}

	/**
	 * Replace p.instagram > a
	 * em iframe
	 */
	mount( $el ) {
		let url = $el.find( 'a' ).attr( 'href' );
		let type = url.replace( /^((http[s]?|ftp):\/)?\/?([^:\/\s]+)(\/(\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/ , '$5' );
		let id = url.replace( /^((http[s]?|ftp):\/)?\/?([^:\/\s]+)(\/(\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/ , '$6' );

		if( type.indexOf('track') >= 0 ){
			type = type.concat('s');
		}

		$el.html(
			$( "<iframe \/>" )
				.attr( 'src' , '//www.deezer.com/plugins/player?format=classic&autoplay=false&playlist=true&width=700&height=350&color=007FEB&layout=dark&size=medium&type='+type+'&id='+id+'&title=&app_id=1')
				.attr( { 
					'width': '100%' ,
					'height':'92' ,
				} )
				.css( {
					'border': '0',
					'overflow': 'hidden',
				} ) 
		) ;
	}
}