/**
 * Permite alterar o tamanho do texto das notícias
 *
 * @author  Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since   2015
 */
export class TextResize {
	/**
     * Construtor
     */
    constructor(storage) {
    	this.storage = storage;
        this.key = "folha_text_resize";
		this.font_size = this.storage.getItem( this.key + "_font_size" );
		this.line_height = this.storage.getItem( this.key + "_line_height" );

    if( window.location.href.indexOf('.shtml') <= 0){
      this.font_size = null;
    }

		if( this.font_size !== null ){
			this.refresh();
		}
		this.display();
    }

	/**
	 * Salva o tamanho da fonte atual
	 */
	save(){
		if ( this.font_size !== null ) {
			this.storage.setItem( this.key + "_font_size", this.font_size );
			this.storage.setItem( this.key + "_line_height", this.line_height );
		}
	}

	/**
	 * Reduz a fonte
	 */
	less(event){
		event.preventDefault();
		var font_size = parseInt( $("#pagination").css("font-size") ) - 2;

		if( font_size >= 10 ){
			this.font_size = font_size;
			this.line_height = parseInt( $("#pagination").css("line-height") ) - 2;
			this.refresh();
			this.save();
		}
	}

	/**
	 * Aumenta a fonte
	 */
	plus(event){
		event.preventDefault();
		var font_size = parseInt( $("#pagination").css("font-size") ) + 2;

		if( font_size <= 28 ){
			this.font_size = font_size;
			this.line_height = parseInt( $("#pagination").css("line-height") ) + 2;
			this.refresh();
			this.save();
		}
	}

	/**
	 * Atualiza o tamanho da fonte
	 */
	refresh(){
		$("#pagination").css("font-size", this.font_size + "px");
		$("#pagination").css("line-height", this.line_height + "px");
	}

	/**
	 * Verifica se é necessário esconder os botões
	 */
	display(){
		var $textsizeMenu = $("#textsize-menu");

		if( $("#view-view").hasClass("content-type-section") ){
			$textsizeMenu.hide();
		}
		else{
			$textsizeMenu.show();
		}
	}
	/**
	 * Registra os eventos
	 */
	registry_events(){
		$(".textsize-menu .less").on( "click" , $.proxy( this.less, this) );
		$(".textsize-menu .plus").on( "click" , $.proxy( this.plus, this) );
	}
}
