/**
 * Spotify
 *
 * @author	Evandro Santos - (ev.santos) <evandro.santos@grupofolha.com.br>
 * @since	2015
 */
export class Spotify {
	/**
	 * Construtor
	 */
	constructor() {
		var base = this ;

		$( "p.spotify" ).each( function() {
			base.mount( $( this ) ) ;
		} ) ;
	}

	/**
	 * Replace p.instagram > a
	 * em iframe
	 */
	mount( $el ) {
		let url = $el.find( 'a' ).attr( 'href' );

		$el.html(
			$( "<iframe \/>" )
				.attr( 'src' , url.replace( /^/ , 'https://embed.spotify.com/?uri=' ) )
				.attr( { 
					'width': '100%' ,
					'height': '80' ,
				} )
				.css( {
					'border': '0',
					'overflow': 'hidden',
				} ) 
		) ;
	}
}