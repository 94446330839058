export class ReadMore {
	/**
	 * Construtor
	 */
	constructor() {
		this.wrap() ;
	}

	wrap() {
		let bt = "<a href='#' onclick='$( \"#bt-read-more-content\" ).next().show().prev().remove();return false;' id='bt-read-more-content'>Continuar lendo </a>" ;
		// Em paginas com video o registry_event não funcionou
		$( "#view-view.content-type-news > article:last > :gt(2)" ).not( ".talktous" ).wrapAll( "<div id='hidden-content'/>" ).parent().before( bt ) ;

		setTimeout(function(){ $("#hidden-content").hide() }, 500);
	}
}
