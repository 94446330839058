/**
 * Notícias
 *
 * @author  Webysther Nunes - (w.faria) <webysther.faria@grupofolha.com.br>
 * @since   2015
 */
export class URL {

    /**
     * Construtor
     */
    constructor(navigation) {
        this.navigation = navigation;
        this.mobile = 'https://m.folha.uol.com.br/';
        this.fotografia = "https://fotografia.folha.com.br";
        this.folha = 'https://www1.folha.uol.com.br';
        this.folha_home = 'https://www.folha.uol.com.br';
        this.classificados = 'https://classificados.folha.com.br';
        this.aovivo = 'https://aovivo.folha.com.br';
        this.no_return = '?mobile';
        this.query = '#view-view a';
        this.fix(this.query);
    }

    /**
     * Altera a URL do desktop para móvel
     */
    fix(query){
        console.groupCollapsed(this.constructor.name + '.' + this.fix.name);

        let urls = $(query).each((index, item) => {
            let url = item.href;
            let new_url = null;
            let regex = null;

            /**
             * Ignora qualquer url que não tenha https://
             */
            if(url.indexOf('http://') !== 0 && url.indexOf('https://') !== 0){
                return;
            }

             /**
             * Padrões antigos
             *
             * Impede que links nos padrões antigos sejam redirecionados para o m.folha.uol.com.br
             * https://www1.folha.uol.com.br/folha/[*]/ult[*]u[*].shtml
             * https://www1.folha.uol.com.br/fsp/[*]/[*].htm
             * https://www1.folha.uol.com.br/fsp/[0-9]{4}/[0-9]{2}/[0-9]{2}/[*]/[*].html
             * 
             */
            regex = new RegExp(/(https:\/\/www1\.folha\.uol\.com\.br\/folha\/[a-z0-9]+\/ult\d+u\d+\.shtml|https:\/\/www1\.folha\.uol\.com\.br\/fsp\/[a-z0-9]+\/[a-z0-9]+\.htm|https:\/\/www1\.folha\.uol\.com\.br\/fsp\/[0-9]{4}\/[0-9]{1,2}\/[0-9]{1,2}\/[a-z0-9]+\/[a-z0-9]+\.html)/) ;
            if ( regex.test(item.href) ) {
                $(item).attr('data-href', url );
                return;
            }

						/**
             * FSP Opinião
             *
             * Impede por exemplo:
             * http://www1.folha.uol.com.br/fsp/opiniao/180051-erramos.shtml
             */
			regex = new RegExp(/(http:\/\/www1\.folha\.uol\.com\.br\/fsp\/opiniao\/[0-9]*-[a-z]*).shtml/) ;
            if ( regex.test(item.href) ) {
                $(item).attr('data-href', url );
                return;
            }

             /**
             * Livraria
             *
             * Impede que links da livraria sejam redirecionados para o m.folha.uol.com.br
             * 
             */
            regex = new RegExp(/(https:\/\/www1\.folha\.uol\.com\.br\/livrariadafolha\/)/) ;
            if ( regex.test(item.href) ) {
                $(item).attr('data-href', url );
                return;
            }

            /**
             * fotografia
             *
             * de: https://fotografia.folha.uol.com.br/galerias/35046-pre-estreia-do-espetaculo-marzipan
             * para: https://m.folha.uol.com.br/gallery/#galeria=35046-pre-estreia-do-espetaculo-marzipan
             */
            regex = new RegExp(/https:\/\/fotografia\.folha\.uol\.com\.br\/galerias\/(nova\/)?\d{3,}-[-a-z0-9]+/) ;
            if ( regex.test(item.href) ) {
                url = url.replace(
                    "https://fotografia.folha.uol.com.br/galerias/",
                    "/gallery/" + this.no_return + "#galeria="
                );

                url = url.replace(
                    "#foto-",
                    ",foto="
                ).replace(
                    "nova/",
                    ""
                );

                const original_url = item.href.split('#');

                new_url = `${this.mobile}${url},fotografia_url=${original_url[0]}`;
                console.log('FOTOGRAFIA URL:', url, new_url);
                $(item).attr('href', new_url );
                $(item).attr('data-href', url );
                return;
            }

            /**
             * vivo
             *
             * de: https://aovivo.folha.uol.com.br/2014/12/02/3774-aovivo.shtml
             * para: https://aovivo.folha.uol.com.br/2014/12/02/3774-m.shtml
             */
            regex = new RegExp(/https:\/\/aovivo\.folha\.uol\.com\.br\/\d{4}\/\d{2}\/\d{2}\/\d{3,}-aovivo\.shtml/) ;
            if ( regex.test(item.href) ) {
                // new_url = url.replace("-aovivo.shtml" , "-m.shtml") + this.no_return;
                new_url = url.replace("-aovivo.shtml" , "-m.shtml");
                console.log(url, new_url);
                $(item).attr('href', new_url );
                $(item).attr('data-href', url );
                return;
            }

            /**
             * classificados
             *
             * de: https://classificados.folha.uol.com.br/veiculos/2015/09/1683438-carros-autonomos-pecam-pelo-excesso-de-cuidado.shtml
             * para: https://m.folha.uol.com.br/veiculos/2015/09/1683438-carros-autonomos-pecam-pelo-excesso-de-cuidado.shtml
             */
            if( url.indexOf("classificados.folha.") !== -1 && url.indexOf(".shtml") !== -1 ){
                new_url = url.replace("classificados.folha" , "m.folha") + this.no_return;
                console.log(url, new_url);
                $(item).attr('href', new_url );
                $(item).attr('data-href', url );
                return;
            }

             /**
             * Olimpiadas
             *
             * Impede que o site da olimpiadas seja redirecionado para o m.folha.uol.com.br
             * 
             */
            if( url.indexOf("olimpiada-no-rio") !== -1 ){
                $(item).attr('data-href', url );
                return;
            }

            /**
            * Revista sãopaulo
            *
            * Impede que o site da revista sãopaulo seja redirecionado para o m.folha.com.br
            *
            */
            if( url.indexOf("saopaulo") !== -1 ){
                $(item).attr('data-href', url );
                return;
            }

            /**
            * Sobretudo
            *
            * Impede que o site da sobretudo seja redirecionado para o m.folha.com.br
            *
            */
            if( url.indexOf("sobretudo") !== -1 ){
                $(item).attr('data-href', url );
                return;
            }

            /**
            * O melhor de são paulo
            *
            * Impede que o site melhor de são paulo seja redirecionado para o m.folha.com.br
            *
            */
            if( url.indexOf("o-melhor-de-sao-paulo") !== -1 ){
                $(item).attr('data-href', url );
                return;
            }

            /**
            * Eleições 2016
            *
            * Impede que o site da Eleições 2016 seja redirecionado para o m.folha.com.br
            *
            */
            if( url.indexOf("poder/eleicoes-2016") !== -1 ){
                $(item).attr('data-href', url );
                return;
            }

            /**
            * REM-F
            *
            * Impede que o site do REM-F seja redirecionado para o m.folha.com.br
            *
            */
            if( url.indexOf("remf") !== -1 ){
                $(item).attr('data-href', url );
                return;
            }

             /**
            * Top Of Mind
            *
            * Impede que o site da Top of Mind seja redirecionado para o m.folha.com.br
            *
            */
            if( url.indexOf("especial/2016/top-of-mind") !== -1 || url.indexOf("topofmind/2016") !== -1 ){
                $(item).attr('data-href', url );
                return;
            }

            /**
            * Loterias
            *
            * Impede que o site do Loterias seja redirecionado para o m.folha.com.br
            *
            */
            if( url.indexOf("loterias") !== -1 ){
                $(item).attr('data-href', url );
                return;
            }

            /**
            * Descubro SP 2017
            *
            * Impede que o site da Descubro SP seja redirecionado para o m.folha.com.br
            *
            */
            if( url.indexOf("especial/2017/descubra-sao-paulo") !== -1 ){
                $(item).attr('data-href', url );
                return;
            }

	       /**
            * Descubro SP 2018
            *
            * Impede que o site da Descubra SP seja redirecionado para o m.folha.com.br
            * 
            */
            if( url.indexOf("especial/2018/descubra-sao-paulo") !== -1 ){
                $(item).attr('data-href', url );
                return;
            }


            /**
             * @about Retorna a notícia posterior a 2017 (> 2017) e diferente do Serafina e Empreendedor Social
             * @more https://regexr.com/3jrfs
             * 
             * @returns RegExp
             */
            if( /^(http(|s):\/\/www1\.folha\.)(.*\/tv|(?!.*\/(serafina|empreendedorsocial|indicadores)\/)).*(\/([2-9]\d([1-9][8-9]|[2-9]\d))\/[0-9]{1,2}\/).*(\.shtml)/.test(url) ){
                $(item).attr('data-href', url );
                return;
            }


            /**
             * notícias
             *
             * de: https://www1.folha.uol.com.br/poder/2015/09/1684688-planalto-escala-ministros-para-compor-frente-anti-impeachment.shtml
             * para: https://m.folha.uol.com.br/poder/2015/09/1684688-planalto-escala-ministros-para-compor-frente-anti-impeachment.shtml
             */
            if( url.indexOf("www1.folha.") !== -1 && url.indexOf(".shtml") !== -1 ){
                new_url = url.replace("www1.folha" , "m.folha") + this.no_return;
                console.log(url, new_url);
                $(item).attr('href', new_url );
                $(item).attr('data-href', url );
                return;
            }

            /**
             * capas
             *
             * de: https://www1.folha.uol.com.br/poder
             * para: https://m.folha.uol.com.br/poder
             */
            if( url.indexOf("www1.folha.") !== -1 && url.indexOf(".shtml") === -1 && url.indexOf("/tv/") === -1 && url.indexOf("/serafina") !== -1 || url.indexOf("/empreendedorsocial") !== -1){
                new_url = url.replace("www1.folha" , "m.folha") + this.no_return;
                console.log(url, new_url);
                $(item).attr('href', new_url );
                $(item).attr('data-href', url );
                return;
            }

            /**
             * capas
             *
             * de: https://classificados1.folha.uol.com.br/
             * para: https://m.folha.uol.com.br/classificados
             */
            if( url.indexOf("classificados1.folha.") !== -1 && url.indexOf(".shtml") === -1 ){
                new_url = this.mobile + "classificados" + this.no_return;
                console.log(url, new_url);
                $(item).attr('href', new_url );
                $(item).attr('data-href', url );
                return;
            }

            /**
             * capas
             *
             * de: https://classificados.folha.uol.com.br/
             * para: https://m.folha.uol.com.br/classificados
             */
            if( url.indexOf("classificados.folha.") !== -1 && url.indexOf(".shtml") === -1 ){
                new_url = this.mobile + "classificados" + this.no_return;
                console.log(url, new_url);
                $(item).attr('href', new_url );
                $(item).attr('data-href', url );
                return;
            }
        });

        console.groupEnd();
        return urls;
    }

    /**
     * Recupera a url da versão clássica
     */
    get_classic_desktop(){
        if(this.navigation.is_section()){
            if(location.pathname.phptrim('/') === 'capa'){
                return this.folha_home + this.no_return;
            }

            return this.folha + location.pathname + this.no_return;
        }

        if(this.navigation.is_news()){
            if(location.pathname.indexOf('/veiculos') === 0){
                return this.classificados + location.pathname + this.no_return;
            }

            if(location.pathname.indexOf('-m.shtml') !== -1){
                return this.aovivo + location.pathname.replace('-m.shtml', '-aovivo.shtml') + this.no_return;
            }

            return this.folha + location.pathname + this.no_return;
        }

        return this.folha + this.no_return;
    }
}
