/**
 * Eleicoes 2016 - Caixas de apuração da Home
 *
 * @author  Hudson Hebert - <hudson.hebert@grupofolha.com.br>
 * @since   2016
 */

export class Eleicoes {
	constructor() {
		this.config = {
			select_uf: $( '.apuracao-na_minha_cidade form.apuracao-filter select[name=estado]' ),
			select_city: $( '.apuracao-na_minha_cidade form.apuracao-filter select[name=cidade]' ),
			btn_search_city: $( '.apuracao-na_minha_cidade button.apuracao-cidade' ),
			select_city_alderman: $( '.apuracao-vereadores form.apuracao-filter select[name=cidade]' ),
			btn_search_city_alderman: $( '.apuracao-vereadores button.apuracao-cidade' ),
			current_alderman_cities: {},
			json_path: 'https://www1.folha.uol.com.br/eleicoes2016/candidates/json/',
			json_path_calculation: 'https://www1.folha.uol.com.br/eleicoes2016/1turno/',
			image_path: "https://staging.f.i.folha.com.br/eleicoes/2016/",
			boxes: $( '.box.apuracao.apuracao-sp, .box.apuracao.prefeitos, .box.apuracao.vereadores' ),
			current_box: 0,
			default_profile_image: '//f.i.uol.com.br/folha/especial/2016/eleicoes/candidatos/images/no_image-140x185.jpg',
		};

		this.init();
	}

	isApuracao() {
		return ( $( '.box.apuracao' ).length > 0 );
	}

	changeUF( uf ) {
		let self = this;
		$.ajax( {
			type:'GET',
			dataType: 'jsonp',
			jsonpCallback: 'cities',
			url: self.config.json_path + 'city_list/' + uf.val().toLowerCase() + '-cities.jsonp',
			success: function( data ) {
				// Recria o select de Cidades
				let target = $( '.apuracao-na_minha_cidade form.apuracao-filter' ),
					new_uf = target.eq( 0 ).find( 'select[name=estado]' ).clone(),
					new_city = target.eq( 1 ).find( 'select[name=cidade]' ).clone(),
					new_search = target.parent().find( 'button' ).clone();

				// Substitui o select bugado pelo recém-criado
				target.eq( 1 ).empty().append( new_city );

				// Limpa o select de Cidades
				new_city.removeAttr( 'disabled' ).empty();
				new_city.append( $( '<option>', { value: '', text: 'Selecione a cidade', disabled: 'disabled', selected: 'selected' } ) );

				// Popula o select de Cidades
				for ( let i = 0; i < data.info.length; i++ ) {
					new_city.append( $( '<option>', { value: data.info[i].city_code, text: data.info[i].city } ) );
				}

				// Substitui o botão bugado pelo recém-criado
				target.parent().find( 'button' ).remove();
				target.parent().append( new_search );
				new_search.attr( 'disabled', 'disabled' );

				new_city.unbind().change( function( e ) {
					let city_id = $( this ).find( 'option:selected' ).text();
					new_search.removeAttr( 'disabled' );
					new_search.unbind().click( function( e ) {
						window.location.href = 'https://www1.folha.uol.com.br/poder/eleicoes-2016/apuracao/#prefeito/1turno/' + uf.val().toLowerCase() + '/' + self.slugify( city_id );
						e.preventDefault();
					} );
					e.preventDefault();
				} );

			},
		} ) ;
	}

	getCitiesAlderman() {
		let self = this;
		$.ajax( {
			type:'GET',
			dataType: 'jsonp',
			jsonpCallback: 'city_list',
			url: self.config.json_path_calculation + 'cities.json',
			success: function( data ) {
				// Armazena cidades para utilização posterior
				self.config.current_alderman_cities = data;
			},
		} ) ;
	}

	changeCityAlderman( city ) {
		let self = this,
			cities = self.config.current_alderman_cities,
			alderman_uf = '',
			alderman_city = '',
			alderman_city_id = '',
			target = $( '.apuracao-vereadores' ),
			new_full = target.find( 'button' ).clone();

		for ( let i = 0; i < cities.length; i++ ) {
			if ( city.val() === cities[i].city_id ) {
				alderman_uf = cities[i].state,
				alderman_city = self.slugify( cities[i].name );
				alderman_city_id = cities[i].city_id;
			}
		}

		// Substitui o botão bugado pelo recém-criado
		target.find( 'button' ).remove();
		target.append( new_full );
		// Mudar URL e habilitar botão "apuração completa"
		new_full.removeAttr( 'disabled' );
		new_full.unbind().click( function( e ) {
			window.location.href = 'https://www1.folha.uol.com.br/poder/eleicoes-2016/apuracao/#vereador/1turno/' + alderman_uf + '/' + alderman_city;
			e.preventDefault();
		} );

		// Popular vereadores
		$.ajax( {
			type:'GET',
			dataType: 'jsonp',
			jsonpCallback: 'candidates',
			url: self.config.json_path_calculation + alderman_uf + '/' + alderman_city_id + '_0013.json',
			success: function( data ) {
				let alderman_list_target = $( '.apuracao-vereadores .result' ),
					alderman_calculation_target = $( '.apuracao-vereadores .info' ),
					calculation_time;

				alderman_list_target.empty();
				if ( data.items.length > 0 ) {
					calculation_time = data.counting_date.split( ' ' )[1].split( ':' );
					calculation_time = calculation_time[0] + 'h' + calculation_time[1];
					alderman_calculation_target.html( '<icon></icon>' + String( data.counted_percentage ).replace( '.', ',' ) + '% das urnas apuradas <span class="update">(atualizado às ' + calculation_time + ')</span>' );
					for ( let i = 0; i < 5; i++ ) {
						alderman_list_target.append( '<div><span class="name">' + data.items[i].name + '</span><span class="partido">' + data.items[i].party_acronym + '</span><span class="votacao">' + String( data.items[i].votes_percentage ).replace( '.', ',' ) + '%</span></div>' );
					}
				} else {
					alderman_calculation_target.html( 'Dados indisponíveis' );
					alderman_list_target.html( '<div><span class="name">Os dados da apuração para esta cidade não estão disponíveis no momento.</span></div>' );
				}
			},
		} ) ;
	}

	setBoxes( index ) {
		let self = this,
			candidate_type,
			json_type = '',
			current_box = self.config.boxes.eq( index );

		if ( current_box.hasClass( 'apuracao-sp' ) ) {
			candidate_type = 'apuracao-sp';
		} else if ( current_box.hasClass( 'prefeitos' ) ) {
			candidate_type = 'prefeitos';
		} else if ( current_box.hasClass( 'vereadores' ) ) {
			candidate_type = 'vereadores';
		}

		switch( candidate_type ) {
			case 'apuracao-sp':
			json_type = current_box.attr( 'data-state' ) + '/' + current_box.attr( 'data-city' ) + '_0011';
			break;
			case 'prefeitos':
			json_type = current_box.attr( 'data-state' ) + '/' + current_box.attr( 'data-city' ) + '_0011';
			break;
			case 'vereadores':
			json_type = current_box.attr( 'data-state' ) + '/' + current_box.attr( 'data-city' ) + '_0013';
			break;
		}

		current_box.find( '.row' ).html('<div id="loading"><img src="//f.i.uol.com.br/app/omelhordesaopaulo/2013/images/loading-tablet.gif"></div>');

		self.getBoxJson( json_type );
	}

	getBoxJson( json_type ) {
		let self = this,
			city_id = json_type.split( '_' )[0].split( '/' )[1];

		$.ajax( {
			type:'GET',
			dataType: 'jsonp',
			jsonpCallback: 'candidates',
			url: self.config.json_path_calculation + json_type + '.json',
			success: function( data ) {
				self.mountBox( data, city_id );
			},
			error: function() {
				setTimeout( function() {
					self.setBoxes( self.config.current_box );
				}, 300 );
			},
		} ) ;
	}

	mountBox( data, city_id ) {
		let self = this,
			el = $( '[data-city=' + city_id + ']' ),
			box_link = el.find( '.box-title a' ),
			box_info = el.find( '.info' ),
			box_content = el.find( '.row' ),
			max_candidates = 2,
			col_type = 'col-lg-6',
			elected_status = '',
			candidate_type = '',
			candidate_image = self.config.default_profile_image,
			calculation_time;

		if ( el.hasClass( 'apuracao-sp' ) ) {
			candidate_type = 'prefeito';
			max_candidates = 4;
			col_type = 'col-lg-3 col-md-3';
		} else if ( el.hasClass( 'prefeitos' ) ) {
			candidate_type = 'prefeito';
		} else if ( el.hasClass( 'vereadores' ) ) {
			candidate_type = 'vereador';
		}

		if ( data.items.length > 0 ) {
			calculation_time = data.counting_date.split( ' ' )[1].split( ':' );
			calculation_time = calculation_time[0] + 'h' + calculation_time[1];
			box_link.html( data.city_name + ' - ' + data.state + '<span class="icon-right"></span>' );
			box_link.attr( 'href', 'https://www1.folha.uol.com.br/poder/eleicoes-2016/apuracao/#' + candidate_type + '/1turno/' + el.attr( 'data-state' ) + '/' + self.slugify( data.city_name ) );
			box_info.html( '<icon></icon>' + String( data.counted_percentage ).replace( '.', ',' ) + '% das urnas apuradas <span class="update">(atualizado às ' + calculation_time + ')</span>' );
			box_content.empty();

			data.items = self.checkElectedStatus(data.items);

			let candidate_type_id;
			for ( let i = 0; i < max_candidates; i++ ) {
				switch( candidate_type ) {
					case 'vereador':
						candidate_type_id = '13';
						break;
					default:
						candidate_type_id = '11';
						break;
				}
				candidate_image = 'https://f.i.uol.com.br/folha/especial/2016/eleicoes/candidatos/' + el.attr( 'data-state' ) + '/' + city_id + '/' + candidate_type_id + '_' + data.items[i].number + '.jpg';
				box_content.append( '<div class="' + col_type + '"><div class="box-item ' + data.items[i].elected_status + '"><div class="box-item-image"><img src="' + candidate_image + '" alt="' + data.items[i].name + '" title="' + data.items[i].name + '"></div><span class="classificacao">' + data.items[i].position + 'º</span><h3 class="name">' + data.items[i].name + '</h3><span class="partido">(' + data.items[i].party_acronym + ')</span><h4 class="votacao">' + String( data.items[i].votes_percentage ).replace( '.', ',' ) + '%</h4></div></div>' );
			}

			self.config.current_box++;
			if ( self.config.current_box < self.config.boxes.length ) {
				self.setBoxes( self.config.current_box );
			}
		}
	}

	checkElectedStatus( data ) {
		// Mayors 2nd turn
		let count = 0;
		$.each(data, function(i) {
			if($(this)[0].elected_status === "elected") {
				count++;
			}
		});

		if (count == 2) {
			$.each(data, function(i) {
				if($(this)[0].elected_status === "elected") {
					$(this)[0].elected_status = "second_turn";
				}
			});
		}

		return data;
	}

	slugify( str ) {
		let from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;',
			to = 'aaaaaeeeeiiiioooouuuunc------';

		if ( $( 'input[name=slugify_chars]' ).length > 0 ) $( 'input[name=slugify_chars]' ).remove();
		$( 'body' ).append( '<input name="slugify_chars" type="hidden" value="àáãäâèéëêìíïîòóöôùúüûñç·/_,:;">' );
		from = $( 'input[name=slugify_chars]' ).val();

		str = str.replace(/^\s+|\s+$/g, '');
		str = str.toLowerCase();

		for (let i = 0, l = from.length; i < l; i++) {
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		str = str.replace(/[^a-z0-9 -]/g, '')
		.replace(/\s+/g, '-')
		.replace(/-+/g, '-');

		return str;
	}

	registerEvents() {
		let self = this;

		if ( self.config.boxes.length > 0 ) {
			self.setBoxes( 0 );
		}

		if ( self.config.select_city_alderman.length > 0 ) {
			self.getCitiesAlderman();
		}

		if ( self.config.select_uf.length > 0 || self.config.select_city_alderman.length > 0 ) {
			setTimeout( function() {
				let videos = $( '.video-article .video-article' );
				if ( videos.length > 0 ) {

					let int_check_video = setInterval( function () {

						let videos_player = videos.find( '.video-player' );
						if ( videos_player.length === ( videos.length * 2 ) ) {

							if ( self.config.select_city_alderman.length > 0 ) {
								let target = $( '.apuracao-vereadores form.apuracao-filter' ),
									select_city;

								// Refaz o select de Cidades
								select_city = '<select name="cidade"><option value="" disabled selected>Selecione a cidade</option>';
								for ( let i = 0; i < self.config.current_alderman_cities.length; i++ ) {
									select_city += '<option value="' + self.config.current_alderman_cities[i].city_id + '">' + self.config.current_alderman_cities[i].name + '</option>';
								}
								select_city += '</select>';

								// Substitui o select bugado pelo recém-criado
								target.empty().append( select_city );

								// Atribui ação ao mudar cidade
								let select_alderman_cities = $( '.apuracao-vereadores form.apuracao-filter select[name=cidade]' );
								select_alderman_cities.change( function( e ) {
									self.changeCityAlderman( $( this ) );
									e.preventDefault();
								} );

								// Inicia selecionando a primeira cidade para exibir os vereadores
								let int_check_mayors = setInterval( function () {
									let caixas = $( '.apuracao.prefeitos' ),
										counter = 0;

									for (let i = 0; i < caixas.length; i++) {
										if ( caixas.eq( i ).find( '.box-item' ).length < 2 ) {
											counter++
										}
									}
									if ( caixas.length === 0 || counter === 0 ) {
										select_alderman_cities.val( self.config.current_alderman_cities[0].city_id ).change();
										clearInterval( int_check_mayors );
									}
								}, 100 );
							}

							if ( self.config.select_uf.length > 0 ) {
								let target = $( '.apuracao-na_minha_cidade form.apuracao-filter:eq(0)' ),
									new_uf = target.find( 'select[name=estado]' ).clone();

								// Substitui o select bugado pelo recém-criado
								target.empty().append( new_uf );

								// Atribui ação ao mudar cidade
								new_uf.change( function( e ) {
									self.changeUF( $( this ) );
									e.preventDefault();
								} );
							}

							clearInterval( int_check_video );
						}
					}, 100 );

				} else {

					if ( self.config.select_uf.length > 0 ) {
						self.config.select_uf.change( function( e ) {
							self.changeUF( $( this ) );
							e.preventDefault();
						} );
					}

					if ( self.config.select_city_alderman.length > 0 ) {
						self.config.select_city_alderman.change( function( e ) {
							self.changeCityAlderman( $( this ) );
							e.preventDefault();
						} );
					}

				}

			}, 500 );

		}

	}

	init() {
		if ( this.isApuracao() ) {
			this.registerEvents();
		}
	}
}
